import {
  Check,
  Delete,
  Edit,
  Grading,
  PersonSearch,
} from '@mui/icons-material';
import {
  Button,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import BaseLabel from 'components/base/BaseLabel';
// import { BaseLinkPainPoint } from 'components/projectLeadDetails/LinkPainPoint';
import { formatDistance } from 'date-fns';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH_ROOT } from 'routes/paths';
import { ConnectedProjectLink } from '../projects/ConnectedProjectLink';
import { ConfirmArchiveListModal } from '../startupList/details/Header';
import { SourcingOrderForCollapsibleScope } from '../startupList/details/ListDetails';
import { DecoratedSourcingOrderForOrdersDetailsDrawer } from './types';

export const SourcingOrderDrawerHeader = ({
  sourcingOrder,
}: {
  sourcingOrder: DecoratedSourcingOrderForOrdersDetailsDrawer;
}) => {
  const [openArchiveListModal, setOpenArchiveListModal] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const { palette, spacing } = useTheme();
  const connectedProject = sourcingOrder.startup_list?.project;

  const dividerSx = {
    background: palette.grey[300],
    height: spacing(1.5),
    marginY: 'auto !important',
  };

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='flex-start'
      paddingBottom={1}
    >
      <Stack marginBottom={4} spacing={0.5} justifyContent='center'>
        <Typography variant='h5' fontWeight={400}>
          {sourcingOrder.startup_list?.title}
          {sourcingOrder.status === 'draft' && (
            <BaseLabel
              sx={({ spacing }) => ({
                paddingX: 1,
                minWidth: spacing(5),
                marginLeft: 1,
              })}
              color='warning'
            >
              Draft
            </BaseLabel>
          )}
        </Typography>
        <Stack direction='row' spacing={1}>
          {!!sourcingOrder.created_by && (
            <>
              <Typography
                component='span'
                variant='body2'
                sx={{ color: 'text.secondary' }}
              >
                {sourcingOrder.status === 'draft' ? 'Started by' : 'Ordered by'}{' '}
                <Tooltip
                  placement='right'
                  title={`${sourcingOrder.created_by.person.email}`}
                >
                  <Typography
                    variant='body2'
                    component='span'
                    sx={{ color: 'text.primary' }}
                  >
                    {sourcingOrder.created_by.person.full_name}
                  </Typography>
                </Tooltip>
              </Typography>
              <Divider sx={dividerSx} orientation='vertical' />
            </>
          )}
          {sourcingOrder.delivery_type && (
            <>
              <Stack direction='row' alignItems='center' gap={0.5}>
                <Typography component='span' variant='body2'>
                  {sourcingOrder.requested_result_type === 'benchmark'
                    ? 'Benchmark'
                    : 'Landscape'}
                </Typography>
              </Stack>
              <Divider sx={dividerSx} orientation='vertical' />
            </>
          )}
          {sourcingOrder.status === 'scouting_completed' ? (
            <>
              <Stack direction='row' alignItems='center' gap={0.5}>
                <Typography component='span' variant='body2'>
                  Delivered
                </Typography>
                <Check sx={{ color: palette.primary.main, fontSize: 16 }} />
              </Stack>
            </>
          ) : sourcingOrder.status === 'in_progress' &&
            sourcingOrder.due_date ? (
            <>
              <Typography
                component='span'
                variant='body2'
                color='text.secondary'
              >
                Expected delivery{' '}
                <Typography
                  component='span'
                  variant='body2'
                  color='text.primary'
                >
                  {formatDistance(
                    new Date(sourcingOrder.due_date),
                    new Date(),
                    {
                      addSuffix: true,
                    },
                  )}
                </Typography>
              </Typography>
            </>
          ) : (
            <></>
          )}
          {connectedProject && (
            <Divider sx={dividerSx} orientation='vertical' />
          )}
          {connectedProject && (
            <ConnectedProjectLink project={connectedProject} />
          )}
        </Stack>
      </Stack>
      <Stack direction='row' spacing={1} alignItems='center'>
        {sourcingOrder.status === 'draft' && (
          <IconButton
            onClick={() => setOpenArchiveListModal(true)}
            size='small'
          >
            <Delete />
          </IconButton>
        )}
        {sourcingOrder.status === 'in_progress' && (
          <Button
            variant='text'
            startIcon={<Edit />}
            onClick={() =>
              navigate(
                PATH_ROOT.sourcings.requestForm(sourcingOrder.startup_list_id),
                {
                  state: {
                    orderOrigin: 'order-in-progress',
                  },
                },
              )
            }
          >
            Edit
          </Button>
        )}
        {sourcingOrder.status === 'draft' && (
          <Button
            color='primary'
            variant='outlined'
            onClick={() =>
              navigate(
                PATH_ROOT.sourcings.requestForm(sourcingOrder.startup_list_id),
                {
                  state: {
                    orderOrigin: 'draft',
                  },
                },
              )
            }
          >
            <Grading sx={{ marginRight: 1 }} />
            Finalize order
          </Button>
        )}
      </Stack>

      {openArchiveListModal && (
        <ConfirmArchiveListModal
          title={sourcingOrder?.startup_list?.title || ''}
          id={sourcingOrder.startup_list_id}
          archiveType='list'
          onHide={() => setOpenArchiveListModal(false)}
          confirmClickSource='order'
        />
      )}
    </Stack>
  );
};

export const ScopeHeader = ({
  sourcingOrder,
  // currentPainPointId,
  // currentStartupListId,
  isReadonly,
}: {
  sourcingOrder?: SourcingOrderForCollapsibleScope;
  // currentPainPointId?: number | null;
  // currentStartupListId: number;
  isReadonly: boolean;
}) => {
  return (
    <Stack direction='row' alignItems='center' marginBottom={0.5}>
      <Typography
        paddingTop={0}
        variant='h5'
        fontWeight={500}
        sx={{ gap: 1, display: 'inline-block' }}
      >
        Pain point
      </Typography>
      {!isReadonly && sourcingOrder && (
        <span
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            marginLeft: 2,
          }}
        >
          <SourcingDeliveryStatus sourcingOrder={sourcingOrder} />
        </span>
      )}
    </Stack>
  );
};

const SourcingDeliveryStatus = ({
  sourcingOrder,
}: {
  sourcingOrder: SourcingOrderForCollapsibleScope;
}) => {
  const { palette } = useTheme();
  return (
    <Tooltip
      placement='top-start'
      title={
        sourcingOrder.status === 'scouting_completed' &&
        sourcingOrder.delivered_date ? (
          <>
            <Stack direction='row' alignItems='center' gap={0.5}>
              <Typography component='span' variant='body2'>
                Sourcing delivered{' '}
                <Typography component='span' variant='body2'>
                  {formatDistance(
                    new Date(sourcingOrder.delivered_date),
                    new Date(),
                    {
                      addSuffix: true,
                    },
                  )}
                </Typography>
              </Typography>
              <Check sx={{ color: palette.primary.main, fontSize: 16 }} />
            </Stack>
          </>
        ) : sourcingOrder.status === 'in_progress' && sourcingOrder.due_date ? (
          <>
            <Typography component='span' variant='body2'>
              Delivery scheduled{' '}
              <Typography component='span' variant='body2'>
                {formatDistance(new Date(sourcingOrder.due_date), new Date(), {
                  addSuffix: true,
                })}
              </Typography>
            </Typography>
          </>
        ) : (
          <></>
        )
      }
    >
      <PersonSearch
        sx={{
          fontSize: 18,
        }}
      />
    </Tooltip>
  );
};
