import { LoadingButton } from '@mui/lab';
import {
  Grid2,
  Skeleton,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { useGetThreadsQuery } from 'apollo/generated/sdkShared';
import { CollapsibleContent } from 'components/base/BaseShowMoreLess';
import { BaseStyledGridButton } from 'components/base/BaseStyledGridButton';
import { useStartStakeholderInspirationConversation } from 'components/engagement/leadsAssistant/useStartStakeholderInspirationConversation';
import TypographyWithEllipsis from 'components/TypographyWithEllipsis';
import useBreakpoints from 'hooks/useBreakpoints';
import { orderBy } from 'lodash';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { PATH_ROOT } from 'routes/paths';

const SearchAssistantPage = () => {
  const { isBelowL } = useBreakpoints();

  return (
    <Stack
      alignItems='center'
      minHeight={'calc(100vh - 264px)'}
      width={isBelowL ? '100%' : `900px`}
      spacing={4}
      marginX={'auto'}
    >
      <Stack
        justifyContent='center'
        alignItems='center'
        spacing={4}
        flexGrow={1}
      >
        <Stack justifyContent='center' alignItems='center' spacing={0.5}>
          <Typography variant={'h6'} textAlign='center'>
            👋 Hey there, let me help you!
          </Typography>
          <Typography
            variant='body1'
            color='text.secondary'
            marginTop={2}
            textAlign='center'
          >
            Understand a space and find startups you care about.
          </Typography>
        </Stack>
      </Stack>
      <RecentSearches sx={{ width: '100%' }} />
      <ChatInputBox
        sx={{
          flexGrow: 0,
          paddingBottom: '26px',
        }}
      />
    </Stack>
  );
};

const RecentSearches = ({ sx }: { sx?: SxProps }) => {
  const { data, loading } = useGetThreadsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const items = orderBy(
    data?.threads.map(thread => ({
      id: thread.id,
      title: thread.title,
    })),
    'id',
    'desc',
  );

  const [showAll, setShowAll] = useState(false);

  if (loading) return <RecentSearchesSkeleton sx={sx} />;
  if (items?.length === 0) return null;

  return (
    <Stack sx={sx}>
      <Typography variant='h6' fontWeight={400} sx={{ marginBottom: 1 }}>
        Recent chats
      </Typography>
      {items?.length < 10 ? (
        <RecentSearchesList items={items} />
      ) : (
        <CollapsibleContent
          isOpen={showAll}
          onToggle={() => setShowAll(!showAll)}
          collapsedSize='34px'
          alwaysExpanded={false}
          actionsSx={{
            marginTop: 1,
          }}
        >
          <RecentSearchesList items={items} />
        </CollapsibleContent>
      )}
    </Stack>
  );
};

const RecentSearchesList = ({
  items,
}: {
  items: {
    id: number;
    title: string;
  }[];
}) => {
  return (
    <Grid2 container spacing={1} columns={10}>
      {items?.map(item => (
        <Grid2 key={item.id} className='grid-item' maxWidth={'25%'}>
          <Link to={PATH_ROOT.search.assistantThreadId(item.id)}>
            <BaseStyledGridButton data-testid={`recently-searched-${item.id}`}>
              <TypographyWithEllipsis variant='inherit' sx={{ color: 'black' }}>
                {item.title}
              </TypographyWithEllipsis>
            </BaseStyledGridButton>
          </Link>
        </Grid2>
      ))}
    </Grid2>
  );
};

const RecentSearchesSkeleton = ({ sx }: { sx?: SxProps }) => {
  return (
    <Stack sx={sx} spacing={2}>
      <Skeleton variant='text' width={400} height={30} />
      <Grid2 container spacing={1} columns={10}>
        {Array.from({ length: 4 }).map((_, index) => (
          <Grid2 key={index}>
            <Skeleton variant='rounded' width={160} height={33.5} />
          </Grid2>
        ))}
      </Grid2>
    </Stack>
  );
};

const ChatInputBox = ({ sx }: { sx?: SxProps }) => {
  const [messageInput, setMessageInput] = useState('');

  const { startConversation, startingConversation } =
    useStartStakeholderInspirationConversation({});

  const handleSubmit = async () => {
    if (!messageInput.trim()) return;

    await startConversation({
      title: messageInput,
      stakeholderId: null,
    });

    setMessageInput('');
  };

  return (
    <TextField
      autoFocus
      multiline
      placeholder='Start by describing a problem or area of interest.'
      maxRows={4}
      value={messageInput}
      onChange={e => setMessageInput(e.target.value)}
      onKeyDown={e => {
        if (e.key === 'Enter' && e.shiftKey) {
          return;
        }

        if (startingConversation) return;

        if (e.key === 'Enter') {
          handleSubmit();
          e.preventDefault();
          return;
        }
      }}
      fullWidth
      sx={sx}
      slotProps={{
        input: {
          sx: {
            paddingY: 1.5,
          },
          endAdornment: (
            <LoadingButton
              sx={{ height: '100%' }}
              onClick={handleSubmit}
              loading={startingConversation}
            >
              Send
            </LoadingButton>
          ),
        },
      }}
    />
  );
};

export default SearchAssistantPage;
