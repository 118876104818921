import { useMemo } from 'react';

export const useCategoryShortAndLongListSplit = <
  CS extends {
    is_in_matrix: boolean;
    is_archived: boolean;
  },
>(
  categorizedStartups: CS[],
) => {
  return useMemo(
    () => ({
      longlist: (categorizedStartups || []).filter(
        x => !x.is_in_matrix && !x.is_archived,
      ),
      shortlist: (categorizedStartups || []).filter(
        x => x.is_in_matrix && !x.is_archived,
      ),
    }),
    [categorizedStartups],
  );
};
