import { Button, styled } from '@mui/material';
import { useGetOrganizationPortalConfig } from 'components/OrganizationPortal/useGetOrganizationPortalConfig';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { usePersonContext } from 'contexts/PersonContext';
import { useSettingsContext } from 'contexts/SettingsContext';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import { PATH_ROOT } from '../../routes/paths';
import ActivityIcon from './assets/activity.svg?react';
import ChatSupportIcon from './assets/chat-support.svg?react';
import CRMIcon from './assets/crm.svg?react';
import DiscoverIcon from './assets/discover.svg?react';
import HelpCenterIcon from './assets/help-center.svg?react';
import ImplementationsIcon from './assets/implementations.svg?react';
import LeadsIcon from './assets/leads.svg?react';
import LibraryIcon from './assets/library.svg?react';
import OrgPortalIcon from './assets/organization-portal.svg?react';
import ProjectsIcon from './assets/projects.svg?react';
import SearchIcon from './assets/search.svg?react';
import StakeholdersIcon from './assets/stakeholders.svg?react';
import ChallengesIcon from './assets/challenges.svg?react';
import ReleaseLogIcon from './assets/release-log.svg?react';

const BaseStyledLabel = styled(Button)(({ theme, size }) => ({
  color: `${theme.palette.primary.main} !important`,
  background: '#EDF6EF',
  position: 'initial',
  ...(size === 'small' ? { minWidth: 33, fontSize: 9.5, paddingY: 0 } : {}),
}));

export const StyledLabel = ({
  labelText,
  size = 'medium',
}: {
  labelText: string;
  size?: 'medium' | 'small';
}) => (
  <BaseStyledLabel size={size ? size : 'medium'} disabled>
    {labelText}
  </BaseStyledLabel>
);

// ----------------------------------------------------------------------

const useBuildSidebarConfig = () => {
  const { existingTeamPortalSlug } = useCheckIfUserTeamHasOrganizationPortal();
  const {
    settings: { HELP_CENTER_LINK, VENTURE_CLIENTING_ACADEMY_LINK },
    loading: helpCenterLoading,
  } = useSettingsContext();
  const [isChatOpen, setIsChatOpen] = useState(false);
  const { isStaff } = useAuth();
  const { activity_enabled } = useCurrentOrganizationFromContext();

  const activityAndStakeholderVisibility = isStaff || activity_enabled;

  return {
    mainNavConfig: [
      // GENERAL
      // ----------------------------------------------------------------------
      {
        subheader: 'Startups',
        items: [
          {
            id: 'tab-home',
            title: 'Discover',
            path: '/',
            icon: <DiscoverIcon />,
            matchEnd: false,
            alternativePath: '/discovery/:projectId',
            show: true,
          },
          {
            id: 'tab-startup-scouter',
            title: 'Search',
            path: PATH_ROOT.search.classic,
            icon: <SearchIcon />,
            endIcon: <StyledLabel labelText='NEW' size='small' />,
            matchEnd: false,
            show: true,
          },
          {
            id: 'tab-startups',
            title: 'CRM',
            path: PATH_ROOT.startups.root,
            icon: <CRMIcon />,
            matchEnd: false,
            show: true,
          },
          {
            id: 'tab-your-library',
            title: 'Library',
            path: PATH_ROOT.lists.root,
            icon: <LibraryIcon />,
            matchEnd: false,
            show: true,
            alternativePath: '/pain-points/:painPointId',
          },
        ],
      },
      {
        subheader: 'Lead Generation',
        items: [
          {
            id: 'tab-organization-portal',
            title: 'Organization portal',
            path: PATH_ROOT.organizationPortalBuilder.home(
              existingTeamPortalSlug,
            ),
            alternativePath: PATH_ROOT.organizationPortalBuilder.root(
              existingTeamPortalSlug,
            ),
            icon: <OrgPortalIcon />,
            matchEnd: false,
            show: true,
          },
          {
            id: 'tab-stakeholders',
            title: 'Stakeholders',
            path: PATH_ROOT.stakeholders.root,
            icon: <StakeholdersIcon />,
            matchEnd: false,
            show: activityAndStakeholderVisibility,
          },
          {
            id: 'tab-challenges',
            title: 'Challenges',
            path: PATH_ROOT.challenges.root,
            icon: <ChallengesIcon />,
            endIcon: <StyledLabel labelText='NEW' size='small' />,
            matchEnd: false,
            show: true,
          },
          {
            id: 'tab-activity',
            title: 'Activity',
            path: PATH_ROOT.activity.root,
            icon: <ActivityIcon />,
            matchEnd: false,
            show: activityAndStakeholderVisibility,
          },
        ],
      },
      // TODO: In task https://glassdollar.atlassian.net/browse/GD2-991
      {
        subheader: 'Projects',
        items: [
          {
            id: 'tab-project-leads',
            title: 'Leads',
            path: PATH_ROOT.projectLeads.list,
            icon: <LeadsIcon />,
            matchEnd: false,
            show: true,
          },
          {
            id: 'tab-pocs',
            title: 'Projects',
            // TODO: change to projects
            path: PATH_ROOT.projects.pocs,
            icon: <ProjectsIcon />,
            matchEnd: false,
            show: true,
          },
          {
            id: 'tab-adoptions',
            title: 'Adoptions',
            // TODO: change to implementations
            path: PATH_ROOT.projects.adoptions,
            icon: <ImplementationsIcon />,
            matchEnd: false,
            show: true,
          },
        ],
      },
    ],
    bottomNavConfig: [
      {
        subheader: '',
        items: [
          {
            id: 'tab-release-log',
            title: 'Release Log',
            // Using the same Link general setting for implementation speed until the change decision is finalized
            path: VENTURE_CLIENTING_ACADEMY_LINK,
            icon: <ReleaseLogIcon />,
            loading: helpCenterLoading,
            matchEnd: false,
            show: true,
            target: '_blank',
          },
          {
            id: 'tab-help-center',
            title: 'Help Center',
            path: HELP_CENTER_LINK,
            icon: <HelpCenterIcon />,
            matchEnd: false,
            show: true,
            loading: helpCenterLoading,
            target: '_blank',
          },
          {
            id: 'tab-chat-support',
            title: 'Chat Support',
            path: '',
            icon: <ChatSupportIcon />,
            matchEnd: false,
            show: true,
            onClick: () => {
              // @ts-expect-error: TODO: FIXME
              window.$chatwoot.toggle();
              setIsChatOpen(!isChatOpen);
            },
          },
        ],
      },
    ],
  };
};

export const useCheckIfUserTeamHasOrganizationPortal = () => {
  const { person } = usePersonContext();
  const possibleTeamSlug = person?.team?.name
    .replace(/\s+/g, '-')
    .toLowerCase();
  const { config: organizationPortalConfig } = useGetOrganizationPortalConfig({
    teamSlug: possibleTeamSlug ?? 'default',
  });

  return {
    existingTeamPortalSlug: organizationPortalConfig?.team_slug ?? 'default',
  };
};

export default useBuildSidebarConfig;
