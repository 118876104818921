import { Box } from '@mui/material';
import BaseErrorMessage from 'components/base/BaseErrorMessage';
import Page404 from 'pages/Page404';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  findOrganizationIdBySubdomain,
  getOrganizationSubdomain,
} from 'utils/general';

type Value = {
  organizationUUID: string;
  organizationAuth0Id: string;
};

const OrganizationUUIDContext = createContext<Value>({
  organizationUUID: '',
  organizationAuth0Id: '',
});

export const OrganizationUUIDProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const organizationSubdomain = getOrganizationSubdomain();

  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState<Value | null>(null);

  useEffect(() => {
    if (!organizationSubdomain) {
      setLoading(false);
      return;
    }

    const fetchOrganizationData = async () => {
      try {
        const { uuid, auth0_id } = await findOrganizationIdBySubdomain(
          organizationSubdomain,
        );
        setValue({ organizationUUID: uuid, organizationAuth0Id: auth0_id });
      } catch (_error) {
        setHasError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchOrganizationData();
  }, [organizationSubdomain]);

  if (loading) {
    return null;
  }
  if (hasError) {
    return (
      <Box height='100vh'>
        <BaseErrorMessage />
      </Box>
    );
  }
  if (!value) return <Page404 />;

  return (
    <OrganizationUUIDContext.Provider value={value}>
      {children}
    </OrganizationUUIDContext.Provider>
  );
};

export const useOrganizationUUIDContext = () => {
  const state = useContext(OrganizationUUIDContext);

  if (!state) {
    throw new Error(
      `useOrganizationUUIDContext must be used within a OrganizationUUIDProvider`,
    );
  }

  return state;
};
