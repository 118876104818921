import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { DecoratedScoutedStartup } from './types';

import { BaseStartupCardHeader } from 'components/base/BaseStartupCard';

const NameCell = ({
  params,
}: {
  params: GridRenderCellParams<DecoratedScoutedStartup, string>;
}) => {
  const startup = params.row;

  const startupDetails = useMemo(
    () => ({
      id: startup.startup_id || -1,
      domain: startup.domain,
      website: startup.website,
      employees_count: startup.employees_count as unknown as number,
      founded_year: startup.founded,
      funding: startup.funding,
      hq: startup.hq,
      case_study_count: startup.case_study_count,
      name: startup.name,
      signal_label: startup.signal_label,
      funnel_label: startup.funnel_label,
    }),
    [startup],
  );

  return <BaseStartupCardHeader startup={startupDetails} avatarSize='medium' />;
};

export { NameCell };
