import { Stack, Typography } from '@mui/material';
import { GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid-pro';

export const SearchGridToolbar = () => {
  const apiRef = useGridApiContext();
  const rowsCount = apiRef.current.getRowsCount();

  // TODO: it'd be good to fetch total companies from the API instead of hardcoding it
  return (
    <GridToolbarContainer>
      <Stack
        direction='row'
        justifyContent='space-between'
        width='100%'
        alignItems='center'
      >
        <Stack direction='row' alignItems='center' spacing={1}>
          <Typography variant='body1'>
            {rowsCount} out of 4M+ companies
          </Typography>
        </Stack>
      </Stack>
    </GridToolbarContainer>
  );
};
