import { Button, SxProps, useTheme } from '@mui/material';
import { MouseEventHandler } from 'react';

export const BaseActionButton = ({
  title,
  open = false,
  sx,
  onClick,
  onMouseUp,
}: {
  title: string;
  open?: boolean;
  sx?: SxProps;
  onClick?: () => void;
  onMouseUp?: MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
  const theme = useTheme();
  const buttonStyles = {
    marginTop: open ? 0 : -4,
    color: theme.palette.info.main,
    fontWeight: theme.typography.fontWeightRegular,
    minWidth: 0,
    paddingX: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
    ...sx,
  };

  return (
    <Button
      sx={buttonStyles}
      onClick={onClick}
      onMouseUp={onMouseUp}
      size='small'
      variant='text'
    >
      {title}
    </Button>
  );
};
