import { Grid2, Stack, Typography } from '@mui/material';
import { useGetRecentSearchesQuery } from 'apollo/generated/sdkShared';
import { BaseStyledGridButton } from 'components/base/BaseStyledGridButton';
import useAuth from 'hooks/useAuth';
import { compact, uniq } from 'lodash';
import SearchCounter from './SearchCounter';

export default function WelcomeToScouter({
  onSubmit,
}: {
  onSubmit: (searchQuery: string) => void;
}) {
  const { user } = useAuth();

  const { data, loading } = useGetRecentSearchesQuery({
    variables: {
      userId: user.id,
      organizationId: parseInt(user.organizationId || '-1'),
    },
    fetchPolicy: 'cache-and-network',
  });

  const userQueries = compact((data?.user || []).map(user => user.query));
  const organizationQueries = compact(
    (data?.organization || []).map(organization => organization.query),
  );

  const recentSearches = uniq([...userQueries, ...organizationQueries]).slice(
    0,
    10,
  );

  return (
    <>
      {!loading && recentSearches.length > 0 && (
        <Stack>
          <Typography variant='h6' fontWeight={400} sx={{ marginBottom: 1 }}>
            Recent searches
          </Typography>
          <Grid2 container spacing={1} columns={10}>
            {recentSearches.map(
              (item, index) => (
                <Grid2 key={index}>
                  <BaseStyledGridButton
                    key={index}
                    data-testid={`recently-searched-${index}`}
                    onClick={() => {
                      onSubmit(item);
                    }}
                  >
                    <Typography variant='inherit'>{item}</Typography>
                  </BaseStyledGridButton>
                </Grid2>
              ),
              [],
            )}
          </Grid2>
        </Stack>
      )}
      <SearchCounter />
    </>
  );
}
