import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { PATH_ROOT } from 'routes/paths';

// CES is between 7 and 12 January 2025
const isCES =
  new Date() >= new Date('2025-01-07') && new Date() <= new Date('2025-01-14');

export const usePitchFormUrl = (startupDomain?: string) => {
  const currentOrganization = useCurrentOrganizationFromContext();
  const pitchFormURL = `${PATH_ROOT.startupPitchFormLink(
    currentOrganization.uuid,
    startupDomain,
  )}${isCES && currentOrganization.subdomain === 'siemens' ? '&source=CES' : ''}`;

  return { pitchFormURL };
};
