import { Stack, TextField, Tooltip } from '@mui/material';
import useAddStartupToList from 'hooks/useAddStartupToList';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useState } from 'react';
import { sanitizeDomain } from 'utils/general';
import { useCategoryShortAndLongListSplit } from '../utils/useCategoryShortAndLongListSplit';
import { StartupsTableContext } from './StartupsTableContext';

type UrlInputFieldProps = {
  website: string;
  setWebsite: React.Dispatch<React.SetStateAction<string>>;
  isDomain: boolean;
  setIsDomain: React.Dispatch<React.SetStateAction<boolean>>;
  onEnter: () => void;
  onEscape: () => void;
};

type AddNewStartupFieldProps = {
  startupListId: number;
  projectCategoryId: number;
  onRemoveStartupRow: () => void;
};
// eslint-disable-next-line
const UrlInputField = React.memo(
  ({
    website,
    setWebsite,
    isDomain,
    setIsDomain,
    onEnter,
    onEscape,
  }: UrlInputFieldProps) => {
    return (
      <TextField
        autoFocus
        variant='outlined'
        data-testid='add-new-startup-field'
        sx={{
          '& input': {
            color: isDomain ? 'secondary.main' : 'inherit',
            transition: 'color 0.3s',
          },
          '& .MuiOutlinedInput-root': {
            height: '3rem',
            '& fieldset': {
              background: 'transparent !important',
            },
            '&:hover fieldset': {
              background: 'transparent !important',
            },
            '&.Mui-focused fieldset': {
              border: ({ palette }) => `${palette.grey['50056']} 1px solid`,
            },
          },
        }}
        placeholder='e.g. airtable.com'
        value={website}
        onChange={e => {
          setWebsite(e.target.value);
          setIsDomain(isValidWebsiteUrl(e.target.value));
        }}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            onEnter();
          }
          if (event.key === 'Escape') {
            onEscape();
          }
        }}
      />
    );
  },
);

export const AddNewStartupField = ({
  startupListId,
  projectCategoryId,
  onRemoveStartupRow,
}: AddNewStartupFieldProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { addStartupToList } = useAddStartupToList();
  const { categorizedStartups } = useContext(StartupsTableContext)!;
  const { shortlist } = useCategoryShortAndLongListSplit(categorizedStartups);

  const [website, setWebsite] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isDomain, setIsDomain] = useState(false);

  const startupExistInList = useCallback(
    () =>
      shortlist.some(
        startup => startup.startup.domain === sanitizeDomain(website),
      ),
    [shortlist, website],
  );

  const handleAddStartup = useCallback(async () => {
    const sanitizedDomain = sanitizeDomain(website);
    if (!isValidWebsiteUrl(sanitizedDomain)) {
      setError('Please enter a valid website URL.');
      return;
    }
    if (startupExistInList()) {
      setError('Startup already in list');
      return;
    }

    try {
      await addStartupToList({
        selectedStartup: {
          domain: sanitizedDomain,
          logo_url: `https://logo.clearbit.com/${sanitizedDomain}?size=80`,
        },
        listId: startupListId,
        categoryId: projectCategoryId,
        logActivity: true,
      });

      onRemoveStartupRow();
    } catch (_error) {
      enqueueSnackbar('Error creating the startup', { variant: 'error' });
    }
  }, [
    website,
    startupListId,
    projectCategoryId,
    onRemoveStartupRow,
    addStartupToList,
    enqueueSnackbar,
    startupExistInList,
  ]);
  return (
    <Stack sx={{ width: '100%' }}>
      <Tooltip title={error} placement='top-start' arrow>
        <span>
          <UrlInputField
            website={website}
            setWebsite={setWebsite}
            isDomain={isDomain}
            setIsDomain={setIsDomain}
            onEnter={handleAddStartup}
            onEscape={onRemoveStartupRow}
          />
        </span>
      </Tooltip>
    </Stack>
  );
};
function isValidWebsiteUrl(url: string) {
  const pattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w-./?%&=]*)?$/i;
  return pattern.test(url);
}
