import { Email, Send } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useMemo } from 'react';

const SendEmailButton = ({
  emails,
  size = 'small',
  onClick,
  disabled,
  icon = 'send',
}: {
  emails: string[];
  size?: 'small' | 'medium' | 'large';
  onClick?: () => void;
  disabled?: boolean;
  icon?: 'send' | 'email';
}) => {
  const href = useMemo(() => {
    let mailto = 'mailto:';

    emails.forEach((email, index) => {
      mailto += `${email}`;

      if (index < emails.length - 1) mailto += ';';
    });

    return mailto;
  }, [emails]);

  return (
    <IconButton
      disabled={disabled}
      size={size}
      target='_blank'
      referrerPolicy='no-referrer'
      href={href}
      onClick={() => onClick && onClick()}
    >
      {icon === 'send' ? <Send fontSize={size} /> : <Email fontSize={size} />}
    </IconButton>
  );
};

export default SendEmailButton;
