import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Stack,
  Typography,
  useTheme,
  Collapse,
  Box,
} from '@mui/material';
import { BaseActionButton } from 'components/base/BaseActionButton';
import { BaseStartupAvatar } from 'components/base/BaseStartupAvatar';
import { BaseStartupListStats } from 'components/base/BaseStartupListStats';

import { motion } from 'framer-motion';
import { varFadeIn } from 'components/animate';
import { discoveryItemStyles } from 'components/dashboard/home/discovery/DiscoverySection';
import { RouterLink } from 'components/RouterLink';
import { PATH_ROOT } from 'routes/paths';
import { useCallback, useState } from 'react';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { SearchStartupListResultV2 } from './types';
import { DEFAULT_LINK_TARGET_GROUP_SLUG } from 'components/OrganizationPortal/shared/usePortalAnalytics';
import { useSharedPagesContext } from 'layouts/SharedPagesLayout';
import { Suppliers } from 'apollo/generated/sdkInnovationManager';
import { SearchSubtitle } from './shared/SearchSubtitle';
const MAX_STARTUP_AVATARS = 5;

const ListStartupAvatars = ({
  startups,
}: {
  startups: Pick<Suppliers, 'domain' | 'logo_url' | 'name'>[];
}) => {
  const visibleStartups = startups.slice(0, MAX_STARTUP_AVATARS);

  return (
    <Stack direction='row' gap={0.5} overflow='hidden'>
      {visibleStartups.map(s => (
        <BaseStartupAvatar
          key={s.domain}
          size='tiny'
          startup={{ domain: s.domain, logo_url: s.logo_url, name: s.name }}
        />
      ))}
    </Stack>
  );
};

export const SearchStartupListResultContainer = ({
  listResults,
  query,
}: {
  listResults: SearchStartupListResultV2[];
  query: string;
}) => {
  const [showMoreLists, setShowMoreLists] = useState(false);

  const handleShowMoreListsClick = () => {
    setShowMoreLists(!showMoreLists);
  };

  return (
    <Box marginTop={5}>
      <SearchSubtitle>Lists by topic</SearchSubtitle>
      <Grid container rowSpacing={2} columnSpacing={3} sx={{ marginTop: 0.15 }}>
        {listResults.slice(0, 6).map((list, i) => {
          return <SearchStartupListResult list={list} query={query} key={i} />;
        })}
      </Grid>
      {listResults.slice(6).length > 0 && (
        <>
          <Collapse in={showMoreLists} timeout='auto' unmountOnExit>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={3}
              sx={{ marginTop: 1 }}
            >
              {listResults.slice(6).map((list, i) => {
                return (
                  <SearchStartupListResult list={list} query={query} key={i} />
                );
              })}
            </Grid>
          </Collapse>
          <BaseActionButton
            onClick={handleShowMoreListsClick}
            title={`Show ${showMoreLists ? 'less' : 'more'}`}
            open={showMoreLists}
            sx={{ marginTop: 3 }}
          />
        </>
      )}
    </Box>
  );
};

const SearchStartupListResult = ({
  list,
  query,
}: {
  list: SearchStartupListResultV2;
  query: string;
}) => {
  const startupsCount = list.project_categories.reduce((acc, curr) => {
    return acc + (curr.categorized_suppliers_aggregate?.aggregate?.count || 0);
  }, 0);

  const categoriesCount =
    list.project_categories_aggregate.aggregate?.count || 0;
  const theme = useTheme();
  const { isSharedPage } = useSharedPagesContext();

  const url = isSharedPage
    ? PATH_ROOT.lists.shared(list.public_uuid, DEFAULT_LINK_TARGET_GROUP_SLUG)
    : PATH_ROOT.lists.details(list.id);
  const trackListResultClick = useCallback(async () => {
    captureAnalyticsEvent('List search result clicked', {
      query,
      listId: list.id,
      listUrl: url,
      listDescription: list.public_description,
      listTitle: list.title,
    });
  }, [list, query, url]);

  const listStartups = list.project_categories.flatMap(
    category => category.categorized_suppliers?.map(cs => cs.supplier) || [],
  );

  return (
    <Grid
      key={list.id}
      item
      {...discoveryItemStyles}
      component={motion.div}
      {...varFadeIn}
    >
      <RouterLink
        to={url}
        state={{
          backToPage: `${window.location.pathname}${window.location.hash}`,
        }}
        onClick={() => {
          trackListResultClick();
        }}
        style={{ textDecoration: 'none' }}
      >
        <Card sx={{ width: '100%', height: '100%', boxShadow: 4 }}>
          <CardActionArea sx={{ height: '100%' }}>
            <CardContent sx={{ height: '100%' }}>
              <Typography>{list.title}</Typography>
              <Stack direction='row' gap={0.5} width='100%' marginTop={1}>
                <BaseStartupListStats
                  totalStartups={startupsCount}
                  totalCategories={categoriesCount}
                  styles={{
                    color: theme.palette.grey[500],
                  }}
                />
              </Stack>
              <Stack direction='row' gap={0.5} width='100%' marginTop={1}>
                <ListStartupAvatars startups={listStartups} />
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
      </RouterLink>
    </Grid>
  );
};
