import { SxProps, Typography } from '@mui/material';

export const SearchSubtitle = ({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: SxProps;
}) => {
  const defaultSx = {
    marginTop: 1,
    fontSize: '1.2rem !important',
    fontWeight: 400,
  };
  return (
    <Typography variant='h5' sx={{ ...defaultSx, ...sx }}>
      {children}
    </Typography>
  );
};
