import { Stack, Typography } from '@mui/material';
import BaseLabel from 'components/base/BaseLabel';
import { PitchMarkdown } from './PitchMarkdown';
import { PitchSchema } from './StartupPitchDetailsTab';

const CustomPitchSubmission = ({
  submission,
  schema,
}: {
  submission: Record<string, string>;
  schema: PitchSchema;
}) => {
  return (
    <Stack gap={1}>
      {schema.fields.map(field => (
        <Stack key={field.id}>
          <Typography variant='subtitle1'>{field.label}</Typography>
          {field.type === 'textarea' && (
            <PitchMarkdown content={submission?.[field.id] || ''} />
          )}
          {field.type === 'multi_select' && (
            <Stack direction='row' gap={1} flexWrap='wrap' marginTop={1}>
              {(submission?.[field.id] as unknown as string[])?.map(
                (item: string) => (
                  <BaseLabel
                    sx={{
                      fontSize: '14px',
                    }}
                    key={item}
                  >
                    {item}
                  </BaseLabel>
                ),
              )}
            </Stack>
          )}
        </Stack>
      ))}
    </Stack>
  );
};

export default CustomPitchSubmission;
