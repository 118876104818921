import { Rocket as RocketIcon } from '@mui/icons-material';
import { MenuItem, Typography } from '@mui/material';
import BaseCopyToClipboard from 'components/base/BaseCopyToClipboard';
import { usePitchFormUrl } from './pitch/usePitchFormUrl';

const CopyClaimProfileLinkMenuItem = ({
  startupDomain,
}: {
  startupDomain: string;
}) => {
  const { pitchFormURL } = usePitchFormUrl(startupDomain);

  return (
    <BaseCopyToClipboard
      text={pitchFormURL}
      successMessage='Link copied to clipboard'
    >
      <MenuItem sx={{ color: 'text.secondary' }}>
        <RocketIcon />
        <Typography
          variant='body2'
          sx={{
            marginLeft: 0.5,
          }}
        >
          Pitch form link
        </Typography>
      </MenuItem>
    </BaseCopyToClipboard>
  );
};

export default CopyClaimProfileLinkMenuItem;
