import { orderBy } from 'lodash';

export const orderByMatrixScore = <
  CS extends {
    matrix_table_score: number;
    categorized_startup_id: number;
  },
>(
  categorizedStartups: CS[],
) => {
  return orderBy(
    categorizedStartups,
    ['matrix_table_score', 'categorized_startup_id'],
    ['desc', 'asc'],
  ).map((orderedStartup, index) => {
    return { ...orderedStartup, order: index + 1 };
  });
};
