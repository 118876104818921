import { CircularProgress, Stack } from '@mui/material';

export const LoadingSimilarStartups = () => {
  return (
    <Stack sx={{ padding: 2, paddingTop: 5 }}>
      <Stack>
        <Stack direction='row' justifyContent='center' sx={{ paddingY: 12 }}>
          <CircularProgress size={110} thickness={2} />
        </Stack>
      </Stack>
    </Stack>
  );
};
