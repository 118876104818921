import { Grid2, Autocomplete, TextField, Typography, Box } from '@mui/material';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { debounce } from 'lodash';
import SelectFilter from 'components/StandardSelectFilter';
import { getCountryFlag } from 'utils/formatLocation';
export type SearchV2Filter = {
  employeesCount: EmployeeCountOptions;
  funding: FundingOptions;
  founded: FoundedOptions;
  countries: string[];
};

type EmployeeCountOptions = '< 100' | '100+' | '250+' | '1000+' | '5000+' | '';
type FoundedOptions =
  | 'last 1 year'
  | 'last 3 years'
  | 'last 5 years'
  | 'last 10 years'
  | '';
type FundingOptions = '< 1M' | '1M+' | '10M+' | '100M+' | '500M+' | '1B+' | '';

const captureFilterFocus = debounce((filterName: string) => {
  captureAnalyticsEvent('Startups search filter focus', {
    filterName,
  });
}, 1000);

export const SearchFilters = ({
  filter,
  handleFilterChange,
  hqOptions,
}: {
  filter: SearchV2Filter;
  handleFilterChange: (
    key: keyof SearchV2Filter,
    value: string | string[],
  ) => void;
  hqOptions: string[];
}) => {
  const fundingOptions: FundingOptions[] = [
    '< 1M',
    '1M+',
    '10M+',
    '100M+',
    '500M+',
    '1B+',
  ];

  const foundedOptions: FoundedOptions[] = [
    'last 1 year',
    'last 3 years',
    'last 5 years',
    'last 10 years',
  ];

  const employeeCountOptions: EmployeeCountOptions[] = [
    '< 100',
    '100+',
    '250+',
    '1000+',
    '5000+',
  ];

  return (
    <Grid2 container spacing={2} marginBottom={2} marginTop={2}>
      <Grid2 size={{ xs: 12, md: 4, lg: 4 }}>
        <SelectFilter
          label='Funding'
          value={filter.funding}
          onFocus={() => captureFilterFocus('Funding')}
          onValueChange={value => handleFilterChange('funding', value)}
          options={fundingOptions}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, md: 4, lg: 4 }}>
        <SelectFilter
          label='Founded'
          value={filter.founded}
          onFocus={() => captureFilterFocus('Founded')}
          onValueChange={value => handleFilterChange('founded', value)}
          options={foundedOptions}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, md: 4, lg: 4 }}>
        <SelectFilter
          label='Employees'
          value={filter.employeesCount}
          onFocus={() => captureFilterFocus('Employees')}
          onValueChange={value => handleFilterChange('employeesCount', value)}
          options={employeeCountOptions}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, md: 4, lg: 4 }} hidden>
        <Autocomplete
          sx={{
            width: '100%',
          }}
          multiple
          disablePortal
          size='small'
          options={hqOptions}
          onFocus={() => captureFilterFocus('HQ Country')}
          renderOption={(props, option) => (
            <Box
              component={'li'}
              sx={{ width: '100%' }}
              {...props}
              key={option}
            >
              <Typography variant='body2' noWrap sx={{ marginRight: 0.5 }}>
                {getCountryFlag(option)}
              </Typography>
              <Typography variant='body2' noWrap>
                {option}
              </Typography>
            </Box>
          )}
          value={filter.countries || []}
          onChange={(_, value) => handleFilterChange('countries', value)}
          renderInput={params => <TextField {...params} label='HQ Country' />}
        />
      </Grid2>
    </Grid2>
  );
};
