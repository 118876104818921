import { ButtonBase, styled } from '@mui/material';

export const BaseStyledGridButton = styled(ButtonBase)(
  ({ theme: { palette, spacing, shadows } }) => ({
    width: '100%',
    borderRadius: spacing(0.5),
    border: `1px solid ${palette.grey[300]}`,
    padding: spacing(1),
    minWidth: 0,
    boxShadow: shadows[1],
    transition: 'background-color 0.2s',
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: palette.primary.luminous,
    },
    '& *': { minWidth: 0, alignItems: 'flex-start' },
  }),
);
