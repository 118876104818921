import { ContentCopy } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';

export const CopyButton = ({
  text,
  color,
}: {
  text: string;
  color: string;
}) => {
  const [openedSnackbar, setOpenSnackbar] = useState(false);

  return (
    <>
      <Tooltip title='Copied' open={openedSnackbar}>
        <span>
          <IconButton
            onClick={() => {
              navigator.clipboard
                .writeText(text)
                .then(() => {
                  setOpenSnackbar(true);
                  setTimeout(() => {
                    setOpenSnackbar(false);
                  }, 1000);
                })
                .catch(e => alert(e));
            }}
          >
            <ContentCopy sx={{ color }} fontSize='small' />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};
