import { Skeleton, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { EnumTableOrganizationStartupPitchStatusesEnum } from 'apollo/generated/sdkShared';
import TypographyWithEllipsis from 'components/TypographyWithEllipsis';
import { BaseCompanyDetails } from 'components/base/BaseCompanyDetails';
import BaseLabel from 'components/base/BaseLabel';
import { BaseStartupAvatar } from 'components/base/BaseStartupAvatar';
import { EmailPersonCell } from 'components/base/EmailPersonCell';
import createColumn from 'components/base/dataGrid/createColumn';
import { getStartupFunnelLabelOrder } from 'components/dashboard/StartupInfoSidePanel/StartupLabelChipSelector';
import StartupFunnelLabelChip from 'components/startups/StartupFunnelLabelChip';
import { getStartupSignalLabelByOrder } from 'components/startups/StartupSignalLabel';
import { useStartupSidePanel } from 'contexts/StartupSidePanelContext';
import { formatDate } from 'utils/datetime';
import { PitchForPitchInbox } from './types';

type StatusType = EnumTableOrganizationStartupPitchStatusesEnum;

export const useGetColumns = (): GridColDef<PitchForPitchInbox>[] => {
  return [
    { field: 'id' },
    createColumn({
      field: 'status',
      headerName: 'Status',
      sortComparator: (v1: StatusType, v2: StatusType) => {
        const order = { new: 1, accepted: 2, rejected: 3 };
        return order[v1] - order[v2];
      },
      renderCell: params => {
        const status = params.value;
        return (
          <Stack alignItems='center' flexDirection='row'>
            {status === 'new' && (
              <BaseLabel
                sx={({ spacing }) => ({
                  paddingX: 1,
                  minWidth: spacing(5),
                })}
                color='warning'
              >
                Pending
              </BaseLabel>
            )}
            {status === 'accepted' && (
              <BaseLabel
                sx={({ spacing }) => ({
                  paddingX: 1,
                  minWidth: spacing(5),
                })}
                color='primary'
              >
                Accepted
              </BaseLabel>
            )}
            {status === 'rejected' && (
              <BaseLabel
                sx={({ spacing }) => ({
                  paddingX: 1,
                  minWidth: spacing(5),
                })}
                color='error'
              >
                Rejected
              </BaseLabel>
            )}
          </Stack>
        );
      },
    }),
    createColumn({
      field: 'name',
      headerName: 'Startup',
      filterable: false,
      valueGetter: params => params.row.startup.name,
      width: 300,
      renderCell: function RenderName(params) {
        const { openStartupSidePanel } = useStartupSidePanel();
        const startup = params.row.startup;

        const label = getStartupFunnelLabelOrder(startup.funnel_labels);
        const signal = getStartupSignalLabelByOrder(startup.signal_labels);

        return (
          <Stack
            direction='row'
            spacing={1}
            alignItems='center'
            width={'100%'}
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              openStartupSidePanel(startup.id, {
                startupListId: undefined,
                pitchId: params.row.id,
              })
            }
          >
            <BaseStartupAvatar
              startup={{
                logo_url: startup.logo_url,
                name: startup.name,
                domain: startup.domain,
              }}
              size='medium'
            />
            <Stack>
              <Stack direction='row' alignItems='center' spacing={1}>
                <TypographyWithEllipsis
                  tooltipTitle={
                    <Typography variant='body2'>{startup.name}</Typography>
                  }
                  tooltipVariant='light'
                  variant='body2'
                  sx={({ palette }) => ({
                    color: palette.secondary.main,
                    cursor: 'pointer',
                    maxWidth: 150,
                  })}
                >
                  {startup.name}
                </TypographyWithEllipsis>
                {label === undefined ? (
                  <Skeleton variant='rounded' width={80} />
                ) : label === null ? null : (
                  <StartupFunnelLabelChip
                    label={label}
                    startupName={startup.name as string}
                  />
                )}
              </Stack>
              <BaseCompanyDetails
                company={{
                  employees_count: startup.employees_count,
                  founded_year: startup.founded_year,
                  funding: startup.funding,
                  hq: startup.hq,
                  // signal TODO: ADD
                  signal: signal,
                }}
              />
            </Stack>
          </Stack>
        );
      },
    }),
    createColumn({ field: 'title', headerName: 'Title', flex: 1 }),
    createColumn({
      field: 'contact_email',
      headerName: 'Contact',
      flex: 1,
      renderCell: params => {
        return <EmailPersonCell value={{ email: params.value }} />;
      },
    }),
    createColumn({
      field: 'created_at',
      headerName: 'Created',
      minWidth: 120,
      renderCell: params => {
        console.log(params.value);
        return (
          <Typography variant='body2'>{formatDate(params.value)}</Typography>
        );
      },
    }),
  ];
};
