import { Box, Divider, Grid, Stack, Tab, Tabs, Tooltip } from '@mui/material';
import { SEARCH_PARAMS } from 'config';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PageTrackingContext } from './Page';
import { ErrorBoundary } from '@sentry/react';
import { Typography } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function CloneProps(props: any) {
  const { children, ...other } = props;
  return children(other);
}

export function LayoutTabs<ITabValue extends string>({
  tabs,
  initialTab,
  changeAllowed,
  children,
  renderSidebar,
  onTabChange,
  tabsActions,
  tabBarWidth,
  syncWithSearchParams = true,
}: {
  tabs: {
    value: ITabValue;
    label: ReactNode | string;
    disabled?: boolean;
    disabledTooltip?: string;
  }[];
  initialTab: ITabValue;
  changeAllowed?: (value: ITabValue, nextValue: ITabValue) => boolean;
  children: FC<React.PropsWithChildren<{ currentTabValue: ITabValue }>>;
  onTabChange?: (nextValue: ITabValue) => void;
  tabsActions?: ReactNode;
  renderSidebar?: () => ReactNode;
  tabBarWidth?: string;
  syncWithSearchParams?: boolean;
}) {
  const { title: pageTitle } = useContext(PageTrackingContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const [currentTabValue, setCurrentTabValue] = useState<ITabValue>(() => {
    if (!syncWithSearchParams) return initialTab;

    const tab = searchParams.get(SEARCH_PARAMS.tab);

    if (tab) {
      captureAnalyticsEvent('Page Tab Visited', {
        type: 'LANDED',
        tab: `${pageTitle} > ${tab}`,
      });

      return tab as ITabValue;
    } else {
      return initialTab;
    }
  });

  useEffect(() => {
    const tabFromSearchParams = searchParams.get(SEARCH_PARAMS.tab);

    if (tabFromSearchParams && tabFromSearchParams !== currentTabValue) {
      setCurrentTabValue(tabFromSearchParams as ITabValue);
    }
  }, [currentTabValue, searchParams]);

  const handleTabChange = (
    _e: React.SyntheticEvent,
    nextValue: ITabValue,
  ): void => {
    if (!changeAllowed || changeAllowed(currentTabValue, nextValue)) {
      if (syncWithSearchParams) {
        setSearchParams({ [SEARCH_PARAMS.tab]: nextValue }, { replace: true });
      }

      setCurrentTabValue(nextValue);

      if (onTabChange) onTabChange(nextValue);

      captureAnalyticsEvent('Page Tab Visited', {
        type: 'CLICK',
        tab: `${pageTitle} > ${nextValue}`,
      });
    }
  };

  return (
    <>
      <Stack justifyContent='space-between' alignItems='center' direction='row'>
        <Tabs
          value={currentTabValue}
          variant='scrollable'
          {...(tabBarWidth ? { sx: { width: tabBarWidth } } : {})}
          onChange={handleTabChange}
        >
          {tabs
            .filter(tab => !!tab.label)
            .map(({ value, label, disabled, disabledTooltip }) => {
              if (disabled && disabledTooltip) {
                /**
                 * This Box triggers a load of warning because Tabs injects
              props that are not valid for div. But if we remove it
              we get an error from the Tooltip saying that it can not have a disabled
              button as a child. CloneProps helps to solve this
              https://github.com/mui/material-ui/issues/12597#issuecomment-455244379
                 */
                return (
                  <CloneProps key={value}>
                    {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                    {(tabProps: any) => (
                      <Tooltip title={disabledTooltip}>
                        <Box
                          sx={({ spacing }) => ({
                            marginRight: spacing(5),
                          })}
                        >
                          <Tab
                            {...tabProps}
                            value={value}
                            label={label}
                            disabled={!!disabled}
                          />
                        </Box>
                      </Tooltip>
                    )}
                  </CloneProps>
                );
              }

              return (
                <Tab
                  key={value}
                  value={value}
                  label={label}
                  disabled={!!disabled}
                />
              );
            })}
        </Tabs>
        {tabsActions && tabsActions}
      </Stack>
      <Box>
        <Divider />
      </Box>
      {renderSidebar ? (
        <Grid container>
          <Grid container item md={9} xs={12}>
            <Stack direction='row' width='100%'>
              {children({ currentTabValue })}
              <Divider
                orientation='vertical'
                sx={{ height: 'unset', margin: 4 }}
              />
            </Stack>
          </Grid>
          <Grid sx={{ paddingTop: 3 }} item xs={12} md={3}>
            {renderSidebar()}
          </Grid>
        </Grid>
      ) : (
        <ErrorBoundary
          fallback={
            <Stack alignItems='center'>
              <Typography variant='body2' color='error'>
                Something went wrong. Use the support chat to report the issue
                if it persists.
              </Typography>
            </Stack>
          }
        >
          {children({ currentTabValue })}
        </ErrorBoundary>
      )}
    </>
  );
}

export function LayoutTabsSkeleton<ITabValue extends string>({
  tabs,
}: {
  tabs: { value: ITabValue; label: ReactNode }[];
}) {
  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Tabs variant='scrollable'>
          {tabs.map(({ value, label }) => {
            return <Tab key={`loading-${value}`} value={value} label={label} />;
          })}
        </Tabs>
      </Box>

      <Box sx={{ marginBottom: 3 }}>
        <Divider />
      </Box>
    </>
  );
}
