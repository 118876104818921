import { AutoAwesome } from '@mui/icons-material';
import {
  Box,
  Drawer,
  Grid2,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { captureException } from '@sentry/react';
import {
  EnumTablePriorityEnum,
  EnumTableStakeholderMaturityEnum,
} from 'apollo/generated/sdkInnovationManager';
import {
  EnumTableStakeholderTypeEnum,
  GetPersonDetailsQuery,
  PeopleSetInput,
  PersonDataFragment,
  useCountPersonNotesQuery,
  useCreatePersonNoteMutation,
  useEnrichStakeholderMutation,
  useGetPersonDetailsQuery,
  usePersonProfileMutation,
} from 'apollo/generated/sdkShared';
import { varFadeIn } from 'components/animate';
import BaseErrorMessage from 'components/base/BaseErrorMessage';
import BaseInitialsAvatar from 'components/base/BaseInitialsAvatar';
import { TooltipPreviewAndCopy } from 'components/base/TooltipPreviewAndCopy';
import { LayoutTabs } from 'components/LayoutTabs';
import MoreMenu from 'components/MoreMenu';
import { PersonNotesSection } from 'components/notes/PersonNotesSection';
import ContentEditableText from 'components/shared/ContentEditableText';
import SendEmailButton from 'components/shared/SendEmailButton';
import AssignTeamButton from 'components/teams/AssignTeamButton';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { formatDistance } from 'date-fns';
import { motion } from 'framer-motion';
import { useSharedPagesContext } from 'layouts/SharedPagesLayout';
import { useSnackbar } from 'notistack';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { PATH_PARAMS, PATH_ROOT } from 'routes/paths';
import { useDebouncedCallback } from 'use-debounce';
import { fDate } from 'utils/datetime';
import { PersonTagsMultiselect } from '../PersonTagsMultiselect';
import ProjectsAndRequestsForPerson from '../ProjectsAndLeadsForPerson';
import {
  maturityValueOptions,
  priorityValueOptions,
  stakeholderTypeValueOptions,
} from '../useGetColumns';
import DetailsDrawerSkeleton from './DetailsDrawerSkeleton';
import { PersonInterestsAutocomplete } from './PersonInterestsAutocomplete';
import SelectDepartment from './SelectDepartment';
import { SectionTitle } from './SectionTitle';
import { StakeholderRecommendedStartups } from './StakeholderRecommendedStartups';

type PersonProfileTabsType = 'profile' | 'stakeholder-notes';

const DrawerContent = ({ personId }: { personId: number }) => {
  const theme = useTheme();
  const { has_teams } = useCurrentOrganizationFromContext();
  const [updatePerson] = usePersonProfileMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { data, loading, error } = useGetPersonDetailsQuery({
    variables: { id: personId },
    skip: !personId,
  });

  const { data: notesCountData, loading: notesCountLoading } =
    useCountPersonNotesQuery({
      variables: { personId },
      skip: !personId,
    });

  const notesCount = notesCountData?.person_notes_count.aggregate?.count || 0;

  const [createPersonNote] = useCreatePersonNoteMutation();
  const [refreshNotes, setRefreshNotes] = useState(false);
  const [enrichStakeholder] = useEnrichStakeholderMutation();

  const isUserProfile = data?.people_by_pk?.user?.id;

  const handleEdit = useCallback(
    (object: PeopleSetInput) => {
      updatePerson({
        variables: {
          person_id: personId,
          object,
        },
      });
    },
    [personId, updatePerson],
  );

  const handleStakeholderEnrich = async () => {
    enqueueSnackbar('Enriching stakeholder...', {
      variant: 'info',
    });

    try {
      const { data } = await enrichStakeholder({
        variables: {
          personId,
        },
      });

      if (!data?.enrich_stakeholder?.success) {
        throw new Error('Action failed to enrich stakeholder');
      }

      await createPersonNote({
        variables: {
          personId,
          body: data?.enrich_stakeholder?.note,
        },
      });
    } catch (error) {
      enqueueSnackbar('Error enriching stakeholder', {
        variant: 'error',
      });
      captureException(error);
    }

    setRefreshNotes(prev => !prev);
  };

  if (loading) return <DetailsDrawerSkeleton />;

  if (error || !data?.people_by_pk) return <BaseErrorMessage />;

  const person = data.people_by_pk;

  return (
    <motion.div {...varFadeIn}>
      <Stack>
        <Stack
          marginTop={2}
          paddingX={2}
          direction='row'
          gap={2}
          justifyContent='space-between'
        >
          <Stack>
            <Stack direction='row' gap={0.5} alignItems='center'>
              <BaseInitialsAvatar full_name={person.full_name} />
              <Stack>
                <Stack direction='row' gap={1}>
                  <ContentEditableText
                    variant='body2'
                    sx={{ color: 'text.secondary', fontSize: '1.2rem' }}
                    isEditable
                    text={person.full_name}
                    onBlur={event => {
                      handleEdit({ full_name: event.target.textContent! });
                    }}
                  />
                  <TooltipPreviewAndCopy
                    textToCopy={person.email}
                    previewContent={person.email}
                  >
                    <span>
                      <SendEmailButton
                        size='small'
                        icon='email'
                        emails={[person.email]}
                        onClick={() => {
                          captureAnalyticsEvent(
                            'Stakeholder contacted via email',
                            {
                              stakeholderEmail: person.email,
                              stakeholderId: person.id,
                              stakeholderName: person.full_name,
                            },
                          );
                        }}
                      />
                    </span>
                  </TooltipPreviewAndCopy>
                </Stack>
                <Stack direction='row' spacing={1} alignItems='center'>
                  <SelectDepartment
                    department={person.organization_department}
                    personId={person.id}
                  />
                  {has_teams && (
                    <AssignTeamButton
                      name={person.full_name}
                      readonly
                      id={person.id}
                      team={person.team}
                    />
                  )}
                </Stack>
              </Stack>
            </Stack>

            {!isUserProfile && (
              <PersonTagsMultiselect
                stakeholderId={person.id}
                stakeholderTags={
                  person.tags.map(t => ({
                    id: t.id,
                    name: t.tag.name,
                  })) || []
                }
                sx={{ margin: '8px 0px', maxWidth: 'unset' }}
              />
            )}
          </Stack>
          <Stack direction='row' alignItems='baseline'>
            <Tooltip title={`Joined on ${fDate(new Date(person.created_at))}`}>
              <Typography variant='body2' fontSize='0.7rem'>
                Joined{' '}
                {formatDistance(new Date(person.created_at), new Date(), {
                  addSuffix: true,
                })}
              </Typography>
            </Tooltip>
            <MoreMenu>
              <Tooltip
                title={
                  !Boolean(person.full_name)
                    ? 'Stakeholder must have a full name to be enriched'
                    : 'Enrich stakeholder'
                }
              >
                <MenuItem
                  onClick={handleStakeholderEnrich}
                  sx={{
                    color: theme.palette.secondary.main,
                  }}
                  disabled={!Boolean(person.full_name)}
                >
                  <AutoAwesome />
                  <Typography variant='body2' sx={{ marginLeft: 0.5 }}>
                    Enrich
                  </Typography>
                </MenuItem>
              </Tooltip>
            </MoreMenu>
          </Stack>
        </Stack>

        <Stack paddingX={2} marginBottom={2}>
          <LayoutTabs<PersonProfileTabsType>
            tabs={[
              { value: 'profile', label: 'Overview' },
              {
                value: 'stakeholder-notes',
                label: (
                  <Stack direction='row' alignItems='center' gap={0.5}>
                    Notes
                    {notesCountLoading ? (
                      <Skeleton variant='circular' width={20} height={20} />
                    ) : (
                      <Typography variant='tab'>({notesCount})</Typography>
                    )}
                  </Stack>
                ),
              },
            ]}
            initialTab={'profile'}
            syncWithSearchParams={false}
          >
            {({ currentTabValue }) => {
              switch (currentTabValue) {
                case 'profile':
                  return (
                    <Box marginTop={1}>
                      <PersonDetailsDrawerProfile
                        person={person}
                        handleEdit={handleEdit}
                      />
                    </Box>
                  );
                case 'stakeholder-notes':
                  return (
                    <PersonNotesSection
                      personId={person.id}
                      refresh={refreshNotes}
                    />
                  );
              }
            }}
          </LayoutTabs>
        </Stack>
      </Stack>
    </motion.div>
  );
};

const PersonDetailsDrawerProfile = ({
  person,
  handleEdit,
}: {
  person: NonNullable<GetPersonDetailsQuery['people_by_pk']>;
  handleEdit: (object: PeopleSetInput) => void;
}) => {
  const isInnovationUnitMember = person.user?.type === 'innovation_manager';
  const notesWrittenCount =
    (person.user?.startup_notes?.length || 0) +
    (person.user?.project_notes?.length || 0);

  return (
    <>
      <Box marginTop={1}>
        <SectionTitle title='About' />
        <Stack gap={2} paddingBottom={2}>
          {!isInnovationUnitMember && (
            <LeadData person={person} handleEdit={handleEdit} />
          )}
          <ProjectsAndRequestsForPerson
            notesWrittenCount={notesWrittenCount}
            projects={person.projects_involved_in}
          />
        </Stack>
      </Box>
      {!isInnovationUnitMember && (
        <Stack paddingBottom={2}>
          <StakeholderRecommendedStartups person={person} />
        </Stack>
      )}
    </>
  );
};

const PersonDetailsDrawer = () => {
  const params = useParams();
  const personId = params[PATH_PARAMS.personId];

  const navigate = useNavigate();
  const { isSharedPage } = useSharedPagesContext();
  const location = useLocation();
  const state = location.state as { backgroundLocation?: Location };

  if (isSharedPage) {
    return null;
  }

  const handleClose = () => {
    if (state?.backgroundLocation) {
      navigate(-1);
    } else {
      navigate(PATH_ROOT.stakeholders.root);
    }
  };

  return (
    <Drawer
      slotProps={{
        backdrop: { className: 'stakeholder-details-drawer-backdrop' },
      }}
      data-testid='person-details-drawer'
      open
      onClose={handleClose}
      anchor='right'
      sx={{ width: 800 }}
      PaperProps={{ sx: { width: 800 } }}
    >
      {personId && <DrawerContent personId={Number(personId)} />}
    </Drawer>
  );
};

function LeadData({
  person,
  handleEdit,
}: {
  person: PersonDataFragment;
  handleEdit: (object: PeopleSetInput) => void;
}) {
  const handleDebouncedEdit = useDebouncedCallback(handleEdit, 1000);
  const [jobTitle, setJobTitle] = useState(person.job_title || '');

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <TextField
          label='Job title'
          variant='standard'
          fullWidth
          data-testid='person-details__job-title'
          value={jobTitle}
          onChange={event => {
            const newValue = event.target.value;
            setJobTitle(newValue);
            handleDebouncedEdit({ job_title: newValue });
          }}
        />
      </Grid2>
      <Grid2 size={4}>
        <TextField
          label='User type'
          select
          fullWidth
          data-testid='person-details__user-type'
          variant='standard'
          value={
            // Allow us to deprecated the innovation_manager type without deleting it from the DB
            !!person.stakeholder_type &&
            person.stakeholder_type !== 'innovation_manager'
              ? person.stakeholder_type
              : ''
          }
          onChange={event => {
            if (!event.target.value) return;

            handleEdit({
              stakeholder_type: event.target
                .value as EnumTableStakeholderTypeEnum,
            });

            captureAnalyticsEvent('Stakeholder metadata updated', {
              stakeholderEmail: person.email,
              stakeholderId: person.id,
              stakeholderName: person.full_name,
              dataType: 'stakeholder_type',
            });
          }}
        >
          {stakeholderTypeValueOptions.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </Grid2>
      <Grid2 size={4}>
        <TextField
          label='Priority'
          select
          variant='standard'
          data-testid='person-details__priority'
          fullWidth
          value={person.priority || ''}
          onChange={event => {
            if (!event.target.value) return;

            handleEdit({
              priority: event.target.value as EnumTablePriorityEnum,
            });

            captureAnalyticsEvent('Stakeholder metadata updated', {
              stakeholderEmail: person.email,
              stakeholderId: person.id,
              stakeholderName: person.full_name,
              dataType: 'priority',
            });
          }}
        >
          {priorityValueOptions.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </Grid2>
      <Grid2 size={4}>
        <TextField
          label='Maturity'
          select
          data-testid='person-details__maturity'
          fullWidth
          variant='standard'
          value={person.maturity || ''}
          onChange={event => {
            if (!event.target.value) return;

            handleEdit({
              maturity: event.target.value as EnumTableStakeholderMaturityEnum,
            });

            captureAnalyticsEvent('Stakeholder metadata updated', {
              stakeholderEmail: person.email,
              stakeholderId: person.id,
              stakeholderName: person.full_name,
              dataType: 'maturity',
            });
          }}
        >
          {maturityValueOptions.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </Grid2>
      <Grid2 size={12}>
        <PersonInterestsAutocomplete person={person} />
      </Grid2>
    </Grid2>
  );
}

export default PersonDetailsDrawer;
