import { useCallback } from 'react';
import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';

type InlineSelectOption = {
  id: number;
  name: string;
  description?: string | null;
};

type BaseInlineSelectOptionProps = {
  categoryOption: InlineSelectOption;
  isChecked: boolean;
  handleSelect: (categoryOption: InlineSelectOption, checked: boolean) => void;
  customTitle?: JSX.Element;
  disabled?: boolean;
};

export const BaseInlineSelectOption = ({
  categoryOption,
  isChecked,
  handleSelect,
  customTitle,
  disabled,
}: BaseInlineSelectOptionProps) => {
  const onCheckboxChange = useCallback(() => {
    handleSelect(categoryOption, !isChecked);
  }, [categoryOption, handleSelect, isChecked]);

  return (
    <FormControlLabel
      disabled={disabled}
      control={<Checkbox checked={isChecked} onChange={onCheckboxChange} />}
      label={
        <Stack spacing={0.5}>
          {customTitle || (
            <Typography variant='body2' fontWeight='bold' paddingTop={0.85}>
              {categoryOption.name}
            </Typography>
          )}
          {categoryOption.description && (
            <Typography variant='body2' color='text.secondary'>
              {categoryOption.description}
            </Typography>
          )}
        </Stack>
      }
      sx={{
        alignItems: 'flex-start',
        margin: 0,
        padding: 1,
        '&:hover': {
          backgroundColor: theme => theme.palette.action.hover,
        },
      }}
    />
  );
};
