import { markdownToTxt } from 'markdown-to-txt';
import {
  Typography,
  Stack,
  IconButton,
  Tooltip,
  Skeleton,
  Box,
} from '@mui/material';
import { Send } from '@mui/icons-material';
import { EnumTableCopilotMessageRolesEnum } from 'apollo/generated/sdkInnovationManager';

import MessageMarkdown from './MessageMarkdown';
import { ForwardedRef, forwardRef, useMemo } from 'react';
import { CopyButton } from 'components/CopyButton';
import { ThreadTargetPerson } from './Conversation';
import LogoShort from 'components/LogoShort';
import { useTrackAsLeadDialog } from './useTrackAsLeadDialog';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { GetThreadMessagesSubscription } from 'apollo/generated/sdkShared';
import { StartupSignal } from 'components/startups/StartupSignalLabel';
import { StartupFunnelLabel } from 'components/dashboard/StartupInfoSidePanel/StartupLabelChipSelector';
import { SolutionCategoriesSelect } from './SolutionCategoriesSelect';
import { RecommendedStartups } from './RecommendedStartups';

// ----------------------------------------------------------------------

export type SolutionCategory = {
  id: number;
  name: string;
  description?: string | null;
};

export type MessageRecommendedStartup = {
  id: number;
  name: string;
  domain: string;
  highlight?: string | null;
  category?: string | null;
  startup: GetThreadMessagesSubscription['messages'][number]['referenced_startups'][number]['startup'] & {
    funnel_label: StartupFunnelLabel | undefined | null;
    signal_label: StartupSignal | undefined;
  };
};

type Props = {
  message: {
    id: number;
    body: string | null | undefined;
    role: EnumTableCopilotMessageRolesEnum;
    created_at: Date;
    email_content: { subject: string; body: string } | null | undefined;
    solution_categories: Array<SolutionCategory>;
    startups: Array<MessageRecommendedStartup>;
  };
  submitMessage: (message: string) => void;
  newMessageLoading: boolean;
  targetPerson: ThreadTargetPerson | null | undefined;
  loading?: boolean;
};

const ChatSkeleton = () => {
  return (
    <Stack spacing={0.5}>
      <Skeleton variant='text' width={410} />
      <Skeleton variant='text' width={360} />
    </Stack>
  );
};

export const MAX_BODY_WIDTH = 720;

const _ConversationMessage = (
  {
    message,
    submitMessage,
    targetPerson,
    loading = false,
    newMessageLoading,
  }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const isUserTheSender = message.role === 'user';
  const emailContent = message.email_content;

  const { openTrackingDialog, trackingDialog } = useTrackAsLeadDialog();

  const body = message.body;

  return (
    <Stack
      direction='row'
      justifyContent={isUserTheSender ? 'flex-end' : 'unset'}
      sx={{ marginBottom: 3 }}
      ref={ref}
    >
      {!isUserTheSender ? (
        <Box marginRight={1} paddingTop={1}>
          <LogoShort width={25} height={25} />
        </Box>
      ) : null}

      <Stack>
        <Stack direction='row' spacing={2}>
          <Stack spacing={1}>
            <Stack
              sx={{
                minWidth: 96,
                maxWidth: MAX_BODY_WIDTH,
                typography: 'body2',
                ...(isUserTheSender
                  ? {
                      padding: 1.5,
                      borderRadius: 1,
                      overflow: 'hidden',
                      bgcolor: 'background.neutral',
                    }
                  : {
                      paddingX: 1.5,
                    }),
              }}
            >
              {loading ? (
                <ChatSkeleton />
              ) : body ? (
                <MessageMarkdown
                  hideLists={
                    message.solution_categories.length > 0 ||
                    message.startups.length > 0
                  }
                >
                  {body}
                </MessageMarkdown>
              ) : isUserTheSender ? (
                <Typography variant='body2' sx={{ color: 'grey.600' }}>
                  <i>No message</i>
                </Typography>
              ) : (
                <ChatSkeleton />
              )}
            </Stack>
            {message.solution_categories.length > 1 && (
              <SolutionCategoriesSelect
                solutionCategories={message.solution_categories}
                submitMessage={submitMessage}
                newMessageLoading={newMessageLoading}
              />
            )}
            {message.startups.length > 0 && (
              <RecommendedStartups
                startups={message.startups}
                submitMessage={submitMessage}
              />
            )}
          </Stack>
          {emailContent && emailContent.body && (
            <Stack direction='row' alignItems='center' spacing={1}>
              {trackingDialog}
              {targetPerson && (
                <Tooltip
                  title={`Send to ${targetPerson.full_name}<${targetPerson.email}>`}
                  placement='bottom'
                  onClick={() => {
                    openTrackingDialog(targetPerson);
                    captureAnalyticsEvent('Leads Assistant Email Previewed');
                  }}
                >
                  <span>
                    <SendEmailAction
                      emails={[targetPerson.email]}
                      subject={emailContent.subject}
                      body={markdownToTxt(
                        convertMarkdownLinks(emailContent.body),
                      )}
                    />
                  </span>
                </Tooltip>
              )}
              <Tooltip title='Copy email content' placement='bottom'>
                <span>
                  <CopyButton
                    text={markdownToTxt(
                      convertMarkdownLinks(emailContent.body),
                    )}
                    color='primary'
                  />
                </span>
              </Tooltip>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

const SendEmailAction = ({
  emails,
  subject,
  body,
}: {
  emails: string[];
  subject?: string;
  body: string;
}) => {
  const href = useMemo(() => {
    let mailto = 'mailto:';

    emails.forEach((email, index) => {
      mailto += `${email}`;

      if (index < emails.length - 1) mailto += ';';
    });

    const subjectPart = subject
      ? `subject=${encodeURIComponent(subject)}&`
      : '';

    mailto += `?${subjectPart}body=${encodeURIComponent(body)}`;

    return mailto;
  }, [emails, subject, body]);

  return (
    <IconButton target='_blank' referrerPolicy='no-referrer' href={href}>
      <Send />
    </IconButton>
  );
};

export const ConversationMessage = forwardRef<HTMLDivElement, Props>(
  _ConversationMessage,
);

const convertMarkdownLinks = (text: string) => {
  return text.replace(/\[(.*?)\]\((.*?)\)/g, '$1 | $2');
};
