import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useTheme,
} from '@mui/material';
import { SEARCH_PARAMS } from 'config';
import { useFormik } from 'formik';
import useAddStartupToList from 'hooks/useAddStartupToList';
import { useCreateFavorites } from 'hooks/useCreateFavorites';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';
import { sanitizeDomain } from 'utils/general';
import * as Yup from 'yup';

const AddStartupSchema = Yup.object().shape({
  website: Yup.string()
    .required()
    .test(
      'domain-validation',
      'Domain is invalid. Example of valid domain formats: https://www.glassdollar.com, www.glassdollar.com or glassdollar.com',
      function (value: string | undefined) {
        // your logic
        if (!value) return false;
        return value.includes('.') && /^\S+$/.test(value);
      },
    )
    .label('Domain'),
});

export default function AddStartupDialog({
  open,
  handleClose,
  startupListId,
  projectCategoryId,
  navigateToLibrary = true,
  title = 'Add startup to your library',
}: {
  open: boolean;
  handleClose: () => void;
  startupListId?: number;
  projectCategoryId?: number;
  navigateToLibrary?: boolean;
  title?: string;
}) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { createFavourites } = useCreateFavorites();
  const { addStartupToList } = useAddStartupToList();
  const navigate = useNavigate();
  const location = useLocation();

  const formik = useFormik({
    initialValues: {
      website: '',
    },
    validationSchema: AddStartupSchema,
    onSubmit: async values => {
      try {
        const domain = sanitizeDomain(values.website);
        let startupListIdToAddStartup: number | undefined;

        if (startupListId) {
          startupListIdToAddStartup = startupListId;
        } else {
          startupListIdToAddStartup = await createFavourites();
        }

        const startup = await addStartupToList({
          selectedStartup: {
            domain: domain,
            logo_url: `https://logo.clearbit.com/${domain}?size=80`,
          },
          listId: startupListIdToAddStartup,
          categoryId: projectCategoryId,
          logActivity: true,
        });

        if (navigateToLibrary) {
          const navigatePath = `${PATH_ROOT.startups.root}?${SEARCH_PARAMS.startupId}=${startup?.id}`;

          navigate(navigatePath);
        } else {
          const navigatePath = `${location.pathname}?${SEARCH_PARAMS.startupId}=${startup?.id}`;

          navigate(navigatePath);
        }

        handleClose();
      } catch (_error) {
        enqueueSnackbar('Error creating the startup', {
          variant: 'error',
        });
      }
    },
  });

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle
          sx={{
            marginBottom: theme.spacing(3),
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent sx={{ paddingY: 1 }}>
          <TextField
            autoFocus
            margin='dense'
            id='website'
            label='Website *'
            type='text'
            fullWidth
            variant='outlined'
            value={formik.values.website}
            onChange={formik.handleChange}
            error={formik.touched.website && Boolean(formik.errors.website)}
            helperText={formik.touched.website && formik.errors.website}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='text' color='inherit' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='contained' type='submit'>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
