import { LowPriority } from '@mui/icons-material';
import { Button, IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useRightTableActions } from 'components/base/useRightTableActions';
import { HighlightCell } from 'components/search/HighlightCell';
import { BaseAddStartupToListMenu } from 'components/base/BaseAddStartupToListMenu';
import { useCallback, useMemo } from 'react';
import { NameCell } from './NameCell';
import { DecoratedScoutedStartup, ScoutStartupResult } from './types';
import useAddStartupToList from 'hooks/useAddStartupToList';
import { useSnackbar } from 'notistack';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { CreateLeadButton } from 'components/projectLeads/CreateLeadButton';
import { useOpenStartupSearchResult } from './useOpenStartupSearchResult';

export const useGetColumns = ({
  onShowSimilarClick,
  query,
  embeddedInCurrentList,
  isOrganizationPortal = false,
}: {
  // eslint-disable-next-line
  onShowSimilarClick: (startupDomain: string, startupId: number) => any;
  query: string;
  embeddedInCurrentList: {
    listId: number;
    categoryId?: number | null;
  } | null;
  isOrganizationPortal?: boolean;
}) => {
  const { spacing, palette } = useTheme();
  const {
    getActionsColumn,
    pinnedRightColumn: dynamicPinnedRightColumn,
    row,
  } = useRightTableActions();
  const { addStartupToList } = useAddStartupToList();
  const { enqueueSnackbar } = useSnackbar();
  const { openSearchStartupSidePanel } = useOpenStartupSearchResult();

  const handleAddStartupToList = useCallback(
    async (startup: ScoutStartupResult) => {
      try {
        const listId = embeddedInCurrentList?.listId;
        const categoryId = embeddedInCurrentList?.categoryId;
        const startupId = startup.startup_id;
        if (!listId || !categoryId)
          throw new Error(
            `List ID or category ID missing: ${listId}, ${categoryId}`,
          );

        await addStartupToList({
          selectedStartup: startup!,
          listId,
          categoryId,
          logActivity: true,
        });
        captureAnalyticsEvent('Startup added to list', {
          startupIds: startupId ? [startupId] : [],
          listId,
          source: 'SEARCH_EMBEDDED',
          listTitle: '',
        });

        enqueueSnackbar('Startup added to list', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Error creating the startup', { variant: 'error' });
        console.error('Error creating the startup', error);
      }
    },
    [addStartupToList, embeddedInCurrentList, enqueueSnackbar],
  );

  const staticActionsColumn = 'static_actions_column' as string;
  const pinnedRightColumns = embeddedInCurrentList
    ? [staticActionsColumn]
    : dynamicPinnedRightColumn;

  const actionsColumn: GridColDef = useMemo(
    () =>
      embeddedInCurrentList
        ? {
            field: staticActionsColumn,
            headerName: staticActionsColumn,
            disableColumnMenu: true,
            sortable: false,
            renderCell: params => {
              const startup = params.row as ScoutStartupResult;
              return (
                <>
                  <Stack
                    alignItems='center'
                    direction='row'
                    width='100%'
                    justifyContent='center'
                  >
                    <Tooltip title='Add to list'>
                      <Button
                        variant='outlined'
                        onClick={() => handleAddStartupToList(startup)}
                        data-testid='add-to-list'
                      >
                        Add
                      </Button>
                    </Tooltip>
                  </Stack>
                </>
              );
            },
          }
        : getActionsColumn({
            minWidth: 160,
            sx: { padding: `${spacing(1)} !important` },
            renderCellNode: params => {
              const startup = params.row as ScoutStartupResult;

              if (!startup) return <></>;
              return (
                <>
                  <BaseAddStartupToListMenu
                    selectedStartups={[params.row]}
                    analyticsSource='SEARCH'
                    buttonSx={{
                      width: spacing(5),
                      height: spacing(5),
                      color: palette.primary.main,
                    }}
                  />
                  <Tooltip title='Search similar'>
                    <IconButton
                      color='primary'
                      sx={{ width: spacing(5), height: spacing(5) }}
                      size='small'
                      onClick={() =>
                        onShowSimilarClick(
                          // eslint-disable-next-line
                          startup?.domain!,
                          // eslint-disable-next-line
                          startup?.startup_id!,
                        )
                      }
                    >
                      <LowPriority fontSize='small' />
                    </IconButton>
                  </Tooltip>
                  <CreateLeadButton
                    source='Search'
                    selectedStartup={{
                      name: startup.name,
                      domain: startup.domain,
                      logo_url: startup.logo_url || '',
                    }}
                  />
                </>
              );
            },
          }),
    [
      embeddedInCurrentList,
      getActionsColumn,
      spacing,
      handleAddStartupToList,
      palette.primary.main,
      onShowSimilarClick,
    ],
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Company',
      minWidth: 350,
      cellClassName: 'company-details',
      renderCell: (
        params: GridRenderCellParams<DecoratedScoutedStartup, string>,
      ) => {
        return (
          <div
            onClick={() => {
              openSearchStartupSidePanel(params.row);

              captureAnalyticsEvent('Startups search result clicked', {
                clickedResultDomain: params.row.domain,
                searchQuery: query,
                clickedResultIndex: -1,
                totalNumberOfResults: -1,
                searchVersion: 'hybrid-2024',
              });
            }}
          >
            <NameCell params={params} />
          </div>
        );
      },
    },
    {
      field: 'highlight',
      headerName: 'Keyword mentions',
      disableColumnMenu: true,
      sortable: false,
      flex: 2,
      align: 'left',
      renderCell: HighlightCell,
    },
  ];
  if (!isOrganizationPortal) {
    columns.push(actionsColumn);
  }

  return {
    pinnedRightColumn: pinnedRightColumns,
    row,
    columns,
  };
};
