import { Box, Chip, Stack, SxProps, Typography } from '@mui/material';
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import {
  useGetAllStakeholdersQuery,
  useUpsertPersonActionMutation,
} from 'apollo/generated/sdkShared';
import { AddPersonModal } from 'components/engagement/people/AddPersonModal';
import { useState } from 'react';
import BaseAddButtonForAutocomplete from '../BaseAddButtonForAutocomplete';
import BaseInitialsAvatar, { avatarSizeSmall } from '../BaseInitialsAvatar';
import { useBaseStakeholderFilterOptions } from './useBaseStakeholderFilterOptions';
import { orderBy } from 'lodash';

export type StakeholderOptionType = {
  id: number;
  email: string;
  full_name: string;
  department?: string | null | undefined;
  previously_selected?: boolean;
};

const AUTOCOMPLETE_STYLES: Record<string, SxProps> = {
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
};

export const BaseStakeholdersSelect = ({
  props,
  placeholder,
  selectedStakeholderId,
  previouslySelectedStakeholderIds = [],
  previouslySelectedMessage,
  onChange,
}: {
  props?: AutocompleteProps<StakeholderOptionType, false, false, false>;
  placeholder: string;
  selectedStakeholderId: number | null;
  previouslySelectedStakeholderIds?: number[];
  previouslySelectedMessage?: string;
  onChange: (value: StakeholderOptionType | null) => void;
}) => {
  const { data, refetch } = useGetAllStakeholdersQuery();
  const [openAddPerson, setOpenAddPerson] = useState(false);

  const [upsertPersonAction] = useUpsertPersonActionMutation();

  const options = orderBy(
    (data?.people || []).map(p => ({
      id: p.id,
      email: p.email,
      full_name: p.full_name,
      department: p.organization_department?.name || null,
      previously_selected: previouslySelectedStakeholderIds.includes(p.id),
    })),
    [p => p.full_name.toLowerCase()],
    ['asc'],
  );

  const filterOptions = useBaseStakeholderFilterOptions();

  const value = options.find(p => p.id === selectedStakeholderId) || null;

  return (
    <>
      <Autocomplete<StakeholderOptionType, false, false, false>
        value={value}
        {...props}
        fullWidth
        options={options}
        filterOptions={filterOptions}
        getOptionLabel={option => option.full_name}
        getOptionDisabled={option =>
          previouslySelectedStakeholderIds.includes(option.id)
        }
        renderOption={(props, option, state) => (
          <StakeholderOption
            key={`stakeholder-option-${option.id}`}
            previouslySelectedMessage={previouslySelectedMessage}
            option={option}
            props={props}
            state={state}
            onNewClick={() => setOpenAddPerson(true)}
          />
        )}
        onChange={(_, value) => {
          onChange(value);
        }}
        noOptionsText={
          <BaseAddButtonForAutocomplete
            text='New Stakeholder'
            divider={false}
            onClick={() => setOpenAddPerson(true)}
          />
        }
        renderInput={params => (
          <TextField
            {...params}
            label='Stakeholder'
            placeholder={placeholder}
          />
        )}
      />

      <AddPersonModal
        onHide={() => setOpenAddPerson(false)}
        onSetValues={async object => {
          const stakeholderData = await upsertPersonAction({
            variables: { object },
          });
          setOpenAddPerson(false);

          const stakeholder = stakeholderData?.data?.upsert_person?.person;
          const stakeholderId = stakeholder?.id;

          if (!stakeholderId) {
            throw new Error('Person ID not found');
          }

          refetch();

          onChange(stakeholder);

          return {
            stakeholderId,
          };
        }}
        open={openAddPerson}
      />
    </>
  );
};

const StakeholderOption = ({
  previouslySelectedMessage,
  option,
  props,
  state,
  onNewClick,
}: {
  previouslySelectedMessage: string | undefined;
  option: StakeholderOptionType;
  props: React.HTMLAttributes<HTMLLIElement>;
  state: { index: number };
  onNewClick: () => void;
}) => {
  return (
    <>
      {state.index === 0 && (
        <BaseAddButtonForAutocomplete
          onClick={onNewClick}
          text='New Stakeholder'
        />
      )}
      <Box
        component='li'
        {...props}
        key={option.id}
        data-testid={`stakeholder-${option.id}`}
        sx={AUTOCOMPLETE_STYLES.listItem}
      >
        <Stack direction='row' spacing={1} alignItems='center' width='100%'>
          <BaseInitialsAvatar
            sx={avatarSizeSmall}
            full_name={option.full_name}
          />
          <Stack>
            <Typography variant='body2'>
              {option.full_name}
              {option.department && ` (${option.department})`}
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              {option.email}
            </Typography>
          </Stack>
          {option.previously_selected ? (
            <Stack flex={1} alignItems='flex-end'>
              <Chip
                label={previouslySelectedMessage}
                color='info'
                size='small'
              />
            </Stack>
          ) : null}
        </Stack>
      </Box>
    </>
  );
};
