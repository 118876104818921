import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { Form, FormikErrors, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { ConfirmRequestFormLeave } from 'pages/request-form/confirmRequestFormLeave';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import DeliverySchedule from '../DeliverySchedule';

import { useCreateDraftSourcingOrderMutation } from 'apollo/generated/sdkShared';
import { ProjectResultTypeField } from 'components/requestFormFields/ProjectResultTypeField';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import {
  ContactSourcingSupportModal,
  useCanOrderSourcing,
} from 'hooks/useCanOrderSourcing';
import { omit } from 'lodash';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';
import { SourcingOrderRedirectOrigin } from 'pages/request-form/ProblemScopeFormLayout';

const SCHEMA_SOURCING_ORDER = z.object({
  title: z.string().min(1, 'Title is required'),
  requested_result_type: z.enum(['benchmark', 'landscape']),
  due_date: z.string({
    required_error: 'Please select a delivery date to continue',
  }),
  delivery_type: z.enum(['standard', 'express']),
  organization_id: z.number(),
});

type SchemaSourcingOrder = z.infer<typeof SCHEMA_SOURCING_ORDER>;

const useDraftSourcingModal = ({
  initialTitle,
  startupListId,
  problemScopeId,
  afterDraftCreated,
  invalidateCache,
  orderOrigin,
}: {
  initialTitle?: string;
  startupListId?: number;
  problemScopeId?: number | null;
  afterDraftCreated?: (listId: number) => Promise<unknown>;
  invalidateCache: null | (() => void);
  orderOrigin: SourcingOrderRedirectOrigin;
}) => {
  const [open, setOpen] = useState(false);
  const { hasSourcingCredits, openContactSupport, setOpenContactSupport } =
    useCanOrderSourcing();

  const [createDraftSourcingOrder] = useCreateDraftSourcingOrderMutation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const currentOrganization = useCurrentOrganizationFromContext();

  const formik = useFormik<SchemaSourcingOrder>({
    initialValues: {
      title: initialTitle || '',
      requested_result_type: 'benchmark',
      due_date: '',
      delivery_type: 'standard',
      organization_id: currentOrganization.id,
    },
    validationSchema: toFormikValidationSchema(SCHEMA_SOURCING_ORDER),
    onSubmit: async (values, { setSubmitting }) => {
      let sourcingOrderId: number | undefined;
      const valuesWithoutTitleAndOrgId = omit(values, [
        'title',
        'organization_id',
      ]);

      try {
        const result = await createDraftSourcingOrder({
          variables: {
            object: {
              ...valuesWithoutTitleAndOrgId,
              startup_list: {
                // -1 is only sent if we don't already have a list
                id: startupListId ? startupListId : -1,
                title: values.title,
                ...(problemScopeId && { scope_id: problemScopeId }),
              },
            },
          },
        });

        sourcingOrderId =
          result.data?.create_draft_sourcing_order?.sourcing_order?.id;

        if (!sourcingOrderId) {
          enqueueSnackbar('Oops.. Something went wrong', {
            variant: 'error',
          });
          return;
        }

        const listIdForOrder =
          result.data?.create_draft_sourcing_order?.sourcing_order
            ?.startup_list_id;

        if (!listIdForOrder) {
          enqueueSnackbar('Oops.. Something went wrong', {
            variant: 'error',
          });
          return;
        }
        await afterDraftCreated?.(listIdForOrder);

        setSubmitting(false);
        setOpen(false);

        invalidateCache?.();

        navigate(PATH_ROOT.sourcings.orderOnList(listIdForOrder), {
          state: {
            orderOrigin,
          },
        });
      } catch (e) {
        captureException(e);
        enqueueSnackbar('Oops.. Something went wrong', { variant: 'error' });
      }
    },
  });

  const { isValid, values, getFieldProps, setFieldValue, touched, errors } =
    formik;

  const openModal = useCallback(() => {
    if (!hasSourcingCredits) {
      setOpenContactSupport(true);
      return;
    }

    setOpen(true);
  }, [hasSourcingCredits, setOpenContactSupport]);

  return [
    openModal,
    <>
      <ContactSourcingSupportModal
        openContactSupport={openContactSupport}
        setOpenContactSupport={setOpenContactSupport}
      />
      {open && (
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth='md'>
          <FormikProvider value={formik}>
            <DialogContent sx={{ paddingBottom: 0 }}>
              <ConfirmRequestFormLeave />
              <Form>
                {!initialTitle && (
                  <Box sx={{ marginTop: 2 }}>
                    <Typography variant='h5' sx={{ marginBottom: 2 }}>
                      Title*
                    </Typography>
                    <TextField
                      {...getFieldProps('title')}
                      fullWidth
                      label='E.g. Increase efficiency for production workers'
                      error={Boolean(errors.title && touched.title)}
                      helperText={touched.title && errors.title}
                    />
                  </Box>
                )}
                <Box marginTop={2}>
                  <ProjectResultTypeField
                    {...{
                      getFieldProps,
                      setFieldValue,
                      touched,
                      errors,
                      requested_result_type: values.requested_result_type,
                      onBlur: () => {},
                      triggerSaveFieldConfirmation: () => {},
                    }}
                  />
                </Box>
                <Box marginTop={2}>
                  <DeliverySchedule />
                </Box>
              </Form>
            </DialogContent>
            <DialogActions sx={{ gap: 1 }}>
              <Button
                variant='text'
                color='inherit'
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Tooltip
                title={
                  (errors as FormikErrors<SchemaSourcingOrder>)?.due_date || ''
                }
              >
                <Box component='span'>
                  <Button
                    variant='contained'
                    onClick={() => {
                      formik.submitForm();
                    }}
                    disabled={!isValid}
                  >
                    Continue
                  </Button>
                </Box>
              </Tooltip>
            </DialogActions>
          </FormikProvider>
        </Dialog>
      )}
    </>,
  ] as const;
};

export default useDraftSourcingModal;
