import { useGetPainPointByIdQuery } from 'apollo/generated/sdkShared';

export const useGetPainPoint = (painPointId?: number) => {
  const { data: scopeData, loading: painPointLoading } =
    useGetPainPointByIdQuery({
      variables: { problemScopeId: painPointId! },
      skip: painPointId === undefined,
      fetchPolicy: 'cache-and-network',
    });

  const painPoint = scopeData?.problem_scopes_by_pk;
  return { painPoint, painPointLoading };
};
