import { Tooltip } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { DateCalendar, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import StandardErrorSection from 'components/StandardErrorSection';
import {
  BUSINESS_DAYS_TILL_FIRST_VALID_SOURCING_DEADLINE,
  B_MAX_ALLOWED_SOURCING_ORDERS_PER_WEEK,
  MAX_ALLOWED_SOURCING_ORDERS_PER_WEEK,
  VW_MAX_ALLOWED_SOURCING_ORDERS_PER_WEEK,
} from 'config';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { parseISO, addBusinessDays } from 'date-fns';
import { useSourcingOrdersInProgress } from 'hooks/useSourcingOrdersInProgress';
import { useMemo } from 'react';
import {
  formatApiDate,
  getWeek,
  isChristmasBreak,
  isPublicHoliday,
  isWeekendOrMonday,
} from 'utils/datetime';

type FormikDatePickerProps = {
  deadlineValue: string;
  setDeadlineValue: (value: string) => void;
};

const useCalendarStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiPickersCalendarHeader-root': {
        marginTop: 0,
      },
    },
  }),
);

const standardSourcingLimitExceeded = (
  date: Date,
  subdomain: string,
  ordersCountPerWeek: Record<number, number>,
) => {
  let maxAllowedSourcingOrdersPerWeek = MAX_ALLOWED_SOURCING_ORDERS_PER_WEEK;
  if (subdomain === 'volkswagen')
    maxAllowedSourcingOrdersPerWeek = VW_MAX_ALLOWED_SOURCING_ORDERS_PER_WEEK;
  if (subdomain === 'bosch')
    maxAllowedSourcingOrdersPerWeek = B_MAX_ALLOWED_SOURCING_ORDERS_PER_WEEK;

  return (
    (ordersCountPerWeek[getWeek(date)] || 0) >= maxAllowedSourcingOrdersPerWeek
  );
};

const RenderDay = (props: PickersDayProps<Date>) => {
  const { subdomain } = useCurrentOrganizationFromContext();
  const { data } = useSourcingOrdersInProgress();

  const limitExceededForWeekday =
    standardSourcingLimitExceeded(
      props.day,
      subdomain,
      data.ordersCountPerWeek,
    ) && !isWeekendOrMonday(props.day);

  let errorTooltip = '';
  if (limitExceededForWeekday) {
    props.sx = { backgroundColor: '#8080804a' };
    errorTooltip = `You've already placed ${MAX_ALLOWED_SOURCING_ORDERS_PER_WEEK} orders for this week.`;
  } else if (baseNonSourcingDates(props.day)) {
    errorTooltip = baseNonSourcingDates(props.day);
  }

  return (
    <Tooltip key={props.key} title={errorTooltip}>
      <span>
        <PickersDay {...props} name='day' />
      </span>
    </Tooltip>
  );
};

const DeliveryCalendarPicker = ({
  deadlineValue,
  setDeadlineValue,
}: FormikDatePickerProps) => {
  const normalizedDeadlineValue = deadlineValue
    ? parseISO(deadlineValue)
    : null;

  const { data, hasError: loadError } = useSourcingOrdersInProgress();
  const minDate = useMemo(() => {
    const estimatedDate = addBusinessDays(
      new Date(),
      BUSINESS_DAYS_TILL_FIRST_VALID_SOURCING_DEADLINE,
    );

    return baseNonSourcingDates(estimatedDate)
      ? addBusinessDays(estimatedDate, 1)
      : estimatedDate;
  }, []);

  const calendarClasses = useCalendarStyles();

  const { subdomain } = useCurrentOrganizationFromContext();

  const shouldDisableDate = (date: Date) => {
    return (
      isWeekendOrMonday(date) ||
      isPublicHoliday(date) ||
      standardSourcingLimitExceeded(date, subdomain, data.ordersCountPerWeek) ||
      isChristmasBreak(date)
    );
  };

  if (loadError)
    return <StandardErrorSection title='Delivery schedule is not available' />;

  return (
    <DateCalendar
      classes={calendarClasses}
      shouldDisableDate={shouldDisableDate}
      value={normalizedDeadlineValue || null}
      minDate={minDate}
      referenceDate={minDate}
      slots={{ day: RenderDay }}
      onChange={value => {
        if (value) {
          setDeadlineValue(formatApiDate(value));
        }
      }}
    />
  );
};

export const baseNonSourcingDates = (date: Date) => {
  let error;
  if (isChristmasBreak(date)) {
    error = 'This date is closed due to the Christmas holidays.';
  } else if (isPublicHoliday(date)) {
    error = 'This date is closed due to public holidays.';
  } else if (isWeekendOrMonday(date)) {
    error = 'Weekends and Mondays are not allowed.';
  } else {
    error = '';
  }
  return error;
};

export default DeliveryCalendarPicker;
