import { Conversation } from 'components/engagement/leadsAssistant/Conversation';
import { useParams } from 'react-router';

const SearchAssistantThreadPage = () => {
  const params = useParams();

  const threadId = Number(params.threadId);

  if (Number.isNaN(threadId)) {
    throw new Error('Invalid thread id');
  }

  return <Conversation threadId={threadId} />;
};

export default SearchAssistantThreadPage;
