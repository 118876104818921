/**
 * Please try to group events as much as possible
 * For example events related with the search can be grouped or also
 * the ones related with stakeholder engagement
 *
 * For non-grouped events try to leave a space every 4 o 5 lines otherwise
 * it is hard as fck to read
 */

import {
  EnumTableEntityVisibilityEnum,
  EnumTableLeadMaturityEnum,
  EnumTableProjectSourcesEnum,
  EnumTableProjectStagesEnum,
} from 'apollo/generated/sdkInnovationManager';
import { SearchVersion } from 'components/search/SearchV2';
import posthog from 'posthog-js';
import { Maybe } from 'yup';
import { OrganizationStartupContactType } from '../@types/organizationStartupContact';
import { identify } from './PosthogAnalytics';

type ConnectionProperties = {
  startupId: number | null | undefined;
  startupName: string | null | undefined;
  startupWebsite: string | null | undefined;
  fromEmail: string;
  toEmail?: string;
  requester?: string;
};

type ShareStartupListProperties = {
  startupListId: number | null | undefined;
  startupListSource: string | null | undefined;
  startupListDeliveredByVE: boolean;
  startupListDeliveryDate: string | null | undefined;
};

type SearchResultClickedProperties = {
  searchQuery: string;
  clickedResultDomain: string;
  clickedResultIndex: number;
  totalNumberOfResults: number;
  searchVersion: SearchVersion;
};

type SearchSubmittedProperties = {
  searchQuery: string;
  searchResultsCount: number;
  searchVersion: SearchVersion;
};

type SearchFilterFocusProperties = {
  filterName: string;
};

type SearchNameSelectedProperties = {
  searchNameQuery: string;
  searchNameOption: string;
};

type SearchSimilarProperties = {
  startupDomain: string;
  startupId: number;
  eventLocation: string;
};

type EntityPermissions = {
  id: number;
  type: 'list' | 'project';
  permission: EnumTableEntityVisibilityEnum;
};

type SimilarStartupProperties = {
  startupId: number;
  similarStartupId: number;
};

type SourcingOrderedProperties = {
  type: 'BRIEFING' | 'DETAIL' | 'INSPIRATION_INTERNAL';
};

type BriefingFormStep = {
  type: 'external' | 'internal';
};

type ShareStartupListEmailProperties = {
  startupListPublicUUID: string;
  emails: string[];
};

type SearchNameResultClickedProperties = {
  searchQuery: string;
  clickedResultDomain: string;
  clickedResultIndex: number;
  totalNumberOfResults: number;
};

export type ANALYTICS_EVENT = {
  BaseError: { url: string };
  'Request connection': ConnectionProperties; // ✅
  'Clicked request connection confirm': ConnectionProperties; // ✅
  'Clicked email link': ConnectionProperties; // ✅

  'Copy share link': ShareStartupListProperties;
  'Copy pitch form link': { startupId?: number };
  'Create share link': ShareStartupListProperties;
  'Share link for list via email': ShareStartupListEmailProperties;
  'Toggle advanced share analytics': { startupListPublicUUID: string };
  'Download project PDF': {
    organizationId: number;
    projectId: number;
  };
  'Download presentation PDF': ShareStartupListProperties & {
    isLandscapeCategory: boolean;
  };
  'Startup profile PDF downloaded': {
    startupId: number;
  };

  'Set entity permissions': EntityPermissions;

  // Search
  'Startups search result clicked': SearchResultClickedProperties; // ✅
  'Startups search submitted': SearchSubmittedProperties; // ✅
  'Startups search filter focus': SearchFilterFocusProperties;
  'Startups search name suggestion selected': SearchNameSelectedProperties; // ✅
  'Search similar startups': SearchSimilarProperties;
  'Search similar startups from startup profile': SearchSimilarProperties;
  'Search similar startups from in-list search': SearchSimilarProperties;
  'Search assistant thread not found': { threadId: number };

  'Sourcing ordered': SourcingOrderedProperties;

  'Similar startup clicked': SimilarStartupProperties;
  'Web search result clicked': SearchNameResultClickedProperties;
  'Startup added to list': {
    startupIds: number[];
    listId: number;
    categoryId?: number;
    listTitle?: string;
    source:
      | 'RECOMMENDATION'
      | 'SEARCH'
      | 'SEARCH_EMBEDDED'
      | 'DISCOVERY_ITEM'
      | 'STARTUP_PROFILE'
      | 'STARTUP_LIST'
      | 'STARTUPS_CRM'
      | 'ASSISTANT';
  };

  'Project Checklist Item Changed': {
    type: 'MANUAL_COMMENT_EDIT' | 'MANUAL_EDIT';
    startupListId: number;
  };
  'Startup List Document Added': {
    type: string; // TODO: This is probably an enum
    startupListId?: number;
    projectId: number;
  };
  'Download Results Clicked': {
    isDiscovery: boolean;
    isLandscape: boolean;
    isLandscapeCategory: boolean;
  };
  'Project Stage Changed': {
    projectId: number;
    currentStage: EnumTableProjectStagesEnum;
    nextStage: EnumTableProjectStagesEnum;
  };
  'Project Lead Maturity Level Changed': {
    projectId: number;
    currentLevel: EnumTableLeadMaturityEnum;
    nextLevel: EnumTableLeadMaturityEnum;
  };
  'Project Stage Changed via Kanban drag and drop':
    | {
        projectId: number;
        currentStage: EnumTableProjectStagesEnum;
        nextStage: EnumTableProjectStagesEnum;
      }
    | {
        projectId: number;
        currentStage: EnumTableProjectStagesEnum;
        nextStageExternal: EnumTableProjectStagesEnum;
      };
  'Project Lead Maturity Level Changed via Kanban drag and drop':
    | {
        projectId: number;
        currentLevel: EnumTableLeadMaturityEnum;
        nextLevel: EnumTableLeadMaturityEnum;
      }
    | {
        projectId: number;
        currentLevel: EnumTableLeadMaturityEnum;
        nextLevelExternal: EnumTableLeadMaturityEnum;
      };
  'Leads Assistant Email Previewed': Record<string, unknown>;
  'Leads Assistant Lead Tracked': Record<string, unknown>;
  'Import Discovery Item to Lists': {
    title: string;
  };
  'Project Stage Change Dialog Opened': {
    projectId: number;
    currentStage: EnumTableProjectStagesEnum;
    nextStage: EnumTableProjectStagesEnum;
  };
  'Startups Selected': {
    selectedStartupsCount: number;
  };
  'Startup Selected': { startupId: number };
  'Startup Deselected': { startupId: number };
  'Page Tab Visited': {
    type: 'LANDED' | 'CLICK';
    tab: string;
  };
  'Page Visited': { page: string };
  'Deprecated Path Visited': {
    path: string;
  };
  'Leads assistant startup clicked': { startupId: number };
  'Search name result clicked': SearchNameResultClickedProperties;
  'Startups import completed': { count: number };
  'Startups import initiated': Record<string, unknown>;
  'Public Session started': Record<string, unknown>;
  'Startup enrichment started': { domain: string };
  'Startup enrichment completed': { domain: string };
  'Startup profile url copied': { domain: string };

  // Deprecated
  'Open Imported Discovery Item': { title: string };
  'Open Discovery Item': { title: string }; // ✅

  // Engagement
  'List Created': { type: 'NEW' | 'FROM_INBOX' | 'DRAFT' };
  'Startup List Note Added': { startupListId: number };
  'Person Note Added': { personId: number };
  'Startup Note Added': { startupListId: number }; // ✅
  'Vote For Startup Added': {
    startupId: number;
    startupListId: number;
  };
  'Project Lead Created': { projectId: number; source: string };
  'Project(PoC) Created': { projectId: number; source: string };
  'Add startup button clicked in empty project': {
    projectId: number;
    stage: EnumTableProjectStagesEnum;
    type: 'project' | 'lead';
  };
  'Lead/Project Linked to List': { projectId: number; source: string };
  'Project Idea Note Added': { projectIdeaId: number };
  'Vote For Startup Removed': {
    startupId: number;
    startupListId: number;
  };
  'Filters Applied': {
    filterArea: string;
    filterValue: string;
  };
  'View List': {
    // ✅
    listId: number;
    listTitle: string;
    listSource: EnumTableProjectSourcesEnum;
    listCategories: string[];
    listType: 'landscape' | 'benchmark';
    isDiscovery: boolean;
    discoveryItemIsImported: boolean;
    assigneeUID?: string;
    assigneeEmail?: string;
  };
  'View List Category': {
    // ✅
    listId: number;
    listTitle: string;
    listSource: EnumTableProjectSourcesEnum;
    listType: 'landscape' | 'benchmark';
    isDiscovery: boolean;
    discoveryItemIsImported: boolean;
    categoryId: number;
    categoryTitle: string;
    assigneeUID?: string;
    assigneeEmail?: string;
  };
  'View Startup': {
    startupId: number;
    startupDomain: string;
    listId?: number;
    listTitle?: string;
    listSource?: EnumTableProjectSourcesEnum;
    listType: 'landscape' | 'benchmark';
    isDiscovery?: boolean;
    discoveryItemIsImported: boolean;
    categoryId?: number;
    categoryTitle?: string;
    assigneeUID?: string;
    assigneeEmail?: string;
  };

  'View Startup > Open case study': {
    startupId: number;
    caseStudyURL?: Maybe<string>;
  };

  'Subscribed to Discovery Item': {
    // ✅
    // This is a startupList but for backwards compatibility it will remain as project
    projectId: number;
    projectTitle: string;
    projectCategories: string[];
    assigneeUID?: string;
    assigneeEmail?: string;
  };
  'Unsubscribed to Discovery Item': {
    // ✅
    // This is a startupList but for backwards compatibility it will remain as project
    projectId: number;
    projectTitle: string;
    projectCategories: string[];
    assigneeUID?: string;
    assigneeEmail?: string;
  };
  'Copy Discovery Item': {
    // ✅
    // This is a startupList but for backwards compatibility it will remain as project
    projectId: number;
    projectTitle: string;
    projectCategories: string[];
    assigneeUID?: string;
    assigneeEmail?: string;
  };
  'User based interests customize button clicked': {
    location?: 'homepage' | 'organization portal';
    userId: number;
  };
  'User selected a theme': {
    location?: 'homepage' | 'organization portal';
    userId: number;
    innovationTheme: string;
  };
  'User acted on ULI onboarding': {
    location?: 'homepage' | 'organization portal';
    userId: number;
    action: 'opened' | 'skipped';
  };
  'View List in Fullscreen': Record<string, unknown>;

  // Request Form
  'Briefing Form Submission Success': BriefingFormStep;

  // Redirect events
  'Redirect to Organization Login': Record<string, unknown>;
  'Redirect to Request form': Record<string, unknown>;
  'Redirect to Create Project': Record<string, unknown>;
  'Redirect to Startups Page': { from: string };
  'Redirect to Scout': Record<string, unknown>;
  'Redirect to Lists': Record<string, unknown>;
  'Redirect to startup profile query param': { path: string };

  // Stakeholders
  'Add Person Modal: New Department button clicked': Record<string, unknown>;
  'Stakeholder contacted via email': {
    stakeholderId: number;
    stakeholderName: string;
    stakeholderEmail: string;
  };
  'Stakeholder email copied': {
    stakeholderId: number;
    stakeholderName: string;
    stakeholderEmail: string;
  };
  'Stakeholder metadata updated': {
    stakeholderId: number;
    stakeholderName: string;
    stakeholderEmail: string;
    dataType: 'maturity' | 'priority' | 'tags' | 'stakeholder_type';
  };
  'Stakeholder details viewed': {
    stakeholderId: number;
  };
  'Stakeholder added from stakeholders page': {
    stakeholderName: string;
    stakeholderEmail: string;
    stakeholderId: number | null;
  };
  'Stakeholder added to PoC scope': {
    stakeholderId: number;
    stakeholderRole: string;
  };
  // Settings
  'User profile viewed': {
    personId: number;
    fullName: string;
  };
  // Teams
  'Assign team to user profile': {
    personId: number;
    teamName: string | null;
  };
  'Create team': {
    teamName: string;
  };
  'Delete team': {
    teamName: string;
  };
  // Server filters
  'Team filter used': {
    teamNames: string[];
    scope: string;
  };
  'Owner filter used': {
    ownerNames: string[];
  };
  'Sourcings-only filter used': {
    isChecked: boolean;
  };
  // Kanban Innovation manager filter
  'Project owner filter used': {
    projectOwnerEmails: string[];
  };
  // Kanban GDManaged filter
  'GDManaged filter used': {
    isChecked: boolean;
  };
  // Kanban Department filter
  'Department filter used': {
    departmentNames: string[];
  };
  // Kanban Health status filter
  'Health status filter used': {
    healthStatus: string;
  };
  // Project Tag filter
  'Project tags filter used': {
    projectTags: string[];
  };
  // Startup list tag filter
  'Startup list tags filter used': {
    startupListTags: string[];
  };
  'Stakeholder tags filter used': {
    tags: string[];
  };
  // Owners
  'Project Owner Added/Modified': {
    stakeholderId: number | null;
  };
  'StartupList Owner Added/Modified': {
    startupListCollaboratorId: number | null;
  };
  // Organization Startup Contact
  'Organization Startup Contact added.': {
    contactId: number | null;
    contactName: string | null;
    contactEmail: string | null;
  };
  'Organization Startup Contact edited.': {
    contactId: number | null;
    oldContactInfo: OrganizationStartupContactType | null;
    newContactInfo: OrganizationStartupContactType | null;
  };
  // Auto fetching Organization Startup Contact
  'Organization Startup Contact selected from fetched contact.': {
    contactId: number | null;
  };
  'Fetched contacts to outreach.': {
    startupId: number | null;
    cachedContactIds: number[] | null;
  };
  // Outreach
  'Outreach modal opened.': {
    startupId: number | null | undefined;
    startupName: string | null | undefined;
  };

  'Outreach started by user.': {
    startupId: number | null | undefined;
    startupName: string | null | undefined;
  };

  'Outreach by GD requested.': {
    startupId: number | null | undefined;
    startupName: string | null | undefined;
  };

  'Connection verified.': {
    connectionId: number | null | undefined;
  };

  'Connection archived.': {
    connectionId: number | null | undefined;
  };

  'Connection marked to follow up.': {
    connectionId: number | null | undefined;
  };

  'App Search > Result clicked': {
    type: 'startup' | 'project' | 'startup_list' | 'discovery_item';
    recordId: number;
  };
  'App Search > Sourcing order click': {
    projectId: number;
  };

  'Open recently viewed':
    | {
        projectId?: number;
      }
    | {
        listId?: number;
      };

  'Open add problem scope': {
    startupListId: number;
  };

  'List search result clicked': {
    query: string;
    listId: number;
    listUrl: string;
    listDescription: Maybe<string>;
    listTitle: string;
  };

  'Opened startup profile from AI recommendations': {
    listId?: number;
    startupId: number;
    startupName: string;
  };

  'Stakeholder recommended startup profile open': {
    stakeholderId: number;
    startupId: number;
  };

  'Section Visited': {
    section: string;
    page: string;
  };

  'File added to scope': {
    listId: number;
  };

  'Engagement history item clicked': {
    activityId: number;
  };
  'Add solution button clicked': {
    projectId: number;
    type: 'project' | 'lead';
    stage: EnumTableProjectStagesEnum;
  };

  'New organization team portal created': {
    teamId: number;
    teamSlug: string;
    teamName: string;
  };

  'New organization portal page created': {
    teamSlug: string;
    pageSlug: string;
    pageName: string;
  };

  'Custom page section added': {
    sectionType: string;
    pageName: string;
  };

  // Challenges
  'Challenge created': { challengeId: number };
  'Challenge stakeholder added': {
    challengeId: number;
    stakeholderRole: string;
  };
  'Challenge share button pressed': { challengeId: number };
  'Challenge share link copied': { challengeId: number };
  'Challenge stakeholders invited via share modal': { challengeId: number };
  'Pain point linked to project': {
    projectId: number;
    painPointId: number | null;
  };
  'Pain point linked to list': {
    listId: number;
    painPointId: number | null;
  };
  'Pain point linked to lead': {
    leadId: number;
    painPointId: number | null;
  };
  'Pain point changed in lead': {
    leadId: number;
    newPainPointId: number | null;
    oldPainPointId?: number | null;
  };
  'Pain point changed in project': {
    projectId: number;
    newPainPointId: number | null;
    oldPainPointId?: number | null;
  };
  'Pain point changed in list': {
    listId: number;
    newPainPointId: number | null;
    oldPainPointId?: number | null;
  };
};

// TODO: Enforce ANALYTICS_EVENT and ANALYTICS_EVENT_PROPERTIES
const captureAnalyticsEvent = <T extends keyof ANALYTICS_EVENT>(
  event: T,
  properties?: ANALYTICS_EVENT[T],
) => {
  if (!import.meta.env.VITE_POSTHOG_API_KEY) {
    console.debug(
      'No Posthog API key found, skipping event',
      event,
      properties,
    );
    return;
  }
  posthog.capture(event, properties);
};

const identifyAnonymousUser = (
  user: {
    name: string;
    email: string;
    department?: string | null;
  },
  organization: { subdomain: string; id: number },
) => {
  identify({ ...user, type: 'visitor' as const }, organization);
};

export { captureAnalyticsEvent, identifyAnonymousUser };
