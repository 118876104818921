import { Theme, useTheme } from '@mui/material';
import {
  EnumTableLeadMaturityEnum,
  EnumTableProjectStagesEnum,
} from 'apollo/generated/sdkInnovationManager';
import { useUpdateProjectLeadMaturityMutation } from 'apollo/generated/sdkShared';

import { BaseStatusSelect } from 'components/base/BaseStatusSelect';
import { useSnackbar } from 'notistack';
import { useChangeProjectStage } from 'pages/dashboard/scoping/hooks/useChangeProjectStage';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useMemo } from 'react';

export const LEAD_MATURITY_LEVELS: Partial<
  Record<EnumTableLeadMaturityEnum, string>
> = {
  pending_request: 'Requested',
  contact_pending: 'Identified',
  in_conversation: 'Connected',
  in_scoping_and_sourcing: 'Sourcing',
  on_hold: 'On Hold',
  discarded_lead: 'Archived',
};

export const LEAD_MATURITY_DISPLAY_NAMES = Object.entries(
  LEAD_MATURITY_LEVELS,
).reduce<Record<EnumTableLeadMaturityEnum, string>>(
  (acc, [stage, label]) => {
    acc[stage as EnumTableLeadMaturityEnum] = label;
    return acc;
  },
  {} as Record<EnumTableLeadMaturityEnum, string>,
);

export const ProjectLeadMaturityLevelSelect = ({
  project,
  readonly,
}: {
  readonly?: boolean;
  project: {
    id: number;
    lead_maturity: EnumTableLeadMaturityEnum;
    stage: EnumTableProjectStagesEnum;
  };
}) => {
  const { handleSubmitChangeStage } = useChangeProjectStage();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const labelStyleMap = useMemo(() => getLabelStyleMap(theme), [theme]);
  const label = project.lead_maturity;
  const labelStyle = labelStyleMap[label] || {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  };

  const [updateLeadMaturity] = useUpdateProjectLeadMaturityMutation();

  const handleMaturityLevelChange = async (
    toMaturityLevel: EnumTableLeadMaturityEnum,
  ) => {
    const currentMaturity = project.lead_maturity;
    try {
      await updateLeadMaturity({
        variables: {
          id: project.id,
          currentMaturity,
          newMaturity: toMaturityLevel,
          trackingPayload: {
            project_id: project.id,
            lead_maturity: toMaturityLevel,
            stage: project.stage,
          },
        },
      });

      // !!! Used in analytics
      if (toMaturityLevel === 'in_scoping_and_sourcing') {
        await handleSubmitChangeStage({
          projectId: project.id,
          fromStage: project.stage,
          toStage: 'sourcing',
        });
      }

      enqueueSnackbar(
        `Lead Maturity updated to ${LEAD_MATURITY_LEVELS[toMaturityLevel]}`,
        {
          variant: 'success',
        },
      );
      captureAnalyticsEvent('Project Lead Maturity Level Changed', {
        currentLevel: currentMaturity,
        nextLevel: toMaturityLevel,
        projectId: project.id,
      });
    } catch (_error) {
      enqueueSnackbar('Failed to update priority', { variant: 'error' });
    }
  };

  return (
    <BaseStatusSelect
      value={project.lead_maturity}
      options={Object.keys(LEAD_MATURITY_DISPLAY_NAMES).map(
        k => k as EnumTableLeadMaturityEnum,
      )}
      optionLabelsMap={LEAD_MATURITY_DISPLAY_NAMES}
      labelStyle={labelStyle}
      readonly={readonly}
      handleChange={toMaturityLevel => {
        if (readonly) {
          return new Promise(() => {});
        }

        return handleMaturityLevelChange(toMaturityLevel);
      }}
    />
  );
};

export const getLabelStyleMap = (
  theme: Theme,
): Partial<
  Record<EnumTableLeadMaturityEnum, { backgroundColor: string; color: string }>
> => ({
  ['contact_pending' as const]: {
    backgroundColor: theme.palette.primary.luminous,
    color: 'black',
  },
  ['in_conversation' as const]: {
    backgroundColor: theme.palette.primary.lighter,
    color: 'black',
  },
  ['pending_request' as const]: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  ['in_scoping_and_sourcing' as const]: {
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
  },
  ['on_hold' as const]: {
    backgroundColor: theme.palette.grey['50016'],
    color: 'black',
  },
  ['discarded_lead' as const]: {
    backgroundColor: theme.palette.error.light,
    color: 'white',
  },
});
