import { DEFAULT_LINK_TARGET_GROUP_SLUG } from 'components/OrganizationPortal/shared/usePortalAnalytics';
import { omit } from 'lodash';
import { PATH_PARAMS, PATH_ROOT } from 'routes/paths';

export const buildListDetailsUrl = (startupListId: number) => {
  const path = PATH_ROOT.lists.details(startupListId);

  return `${window.location.protocol}//${window.location.host}${path}`;
};

export const buildStartupListResultsUrl = ({
  startupListPublicUUID,
  slug,
}: {
  startupListPublicUUID: string;
  slug: string;
}): string => {
  const path = PATH_ROOT.lists.shared(startupListPublicUUID, slug);

  return `${window.location.protocol}//${window.location.host}${path}`;
};

export const buildPublicLandscapeCategoryUrl = ({
  listPublicUUID,
  categoryId,
  slug,
}: {
  listPublicUUID: string;
  categoryId: number;
  slug: string;
}): string => {
  const path = PATH_ROOT.lists.sharedCategory({
    publicUUID: listPublicUUID,
    categoryId,
    shareSlug: slug,
  });

  return `${window.location.protocol}//${window.location.host}${path}`;
};

export const buildPublicChallengeUrl = ({
  challengePublicUUID,
}: {
  challengePublicUUID: string;
}): string => {
  const path = PATH_ROOT.challenges.shared({
    publicUUID: challengePublicUUID,
  });

  return `${window.location.protocol}//${window.location.host}${path}`;
};

export const getRootUrl = () => {
  return `${window.location.protocol}//${window.location.host}`;
};

export const buildStartupPlatformDomain = () => {
  if (import.meta.env.DEV) return `${window.location.protocol}//localhost:3003`;

  if (import.meta.env.VITE_ENV === 'production')
    return `${window.location.protocol}//startups.glassdollar.com`;

  return `${window.location.protocol}//staging-startups.glassdollar.com`;
};

export const buildAdminPlatformDomain = () => {
  if (import.meta.env.MODE === 'development')
    return `${window.location.protocol}//localhost:3004`;

  if (import.meta.env.MODE === 'production')
    return `${window.location.protocol}//startups-admin.glassdollar.com`;

  return `${window.location.protocol}//staging-startups-admin.glassdollar.com`;
};

export const buildBaseOrganizationUrl = ({
  subdomain,
}: {
  subdomain: string;
}) => {
  if (import.meta.env.DEV) {
    // Use localstore for the subdomain in development
    return `${window.location.protocol}//localhost:3000`;
  }
  if (import.meta.env.VITE_ENV === 'staging')
    return `${window.location.protocol}//staging-app-${subdomain}.glassdollar.com`;

  return `${window.location.protocol}//${subdomain}.glassdollar.com`;
};

export const buildInboxRequestUrl = ({ projectId }: { projectId: number }) => {
  const path = PATH_ROOT.requests.details(projectId);
  return `${window.location.protocol}//${window.location.host}${path}`;
};

export const buildProjectScopeUrl = ({
  projectPublicUUID,
}: {
  projectPublicUUID: string;
}) => {
  return PATH_ROOT.scopeLink() + '/' + projectPublicUUID;
};

export const buildListScopeUrl = ({
  startupListPublicUUID,
  teamSlug,
}: {
  startupListPublicUUID: string;
  teamSlug?: string;
}) => {
  return PATH_ROOT.listScopeLink(teamSlug) + '/' + startupListPublicUUID;
};

export const stripScheme = (url: string) => url.replace(/^https?:\/\//, '');

export const extractDomain = (url: string) =>
  url.replace(/(www\.|https?:\/\/)/, '').replace(/\/.*/, '');

export const addProtocolIfNotUrl = (url: string) => {
  if (!/^https?:\/\//i.test(url)) {
    url = 'https://' + url;
  }
  return url;
};

export const buildProjectPreviewUrl = (projectPublicUUID: string) => {
  const path = PATH_ROOT.lists.shared(
    projectPublicUUID,
    DEFAULT_LINK_TARGET_GROUP_SLUG,
  );
  return `${getRootUrl()}${path}`;
};

export const buildOrderScoutingFromLandscapeUrl = ({
  categoryId,
  projectId,
}: {
  categoryId: number;
  projectId: number;
}) => {
  return `${getRootUrl()}${PATH_ROOT.lists.preview_create_from_inspiration(
    categoryId,
    projectId,
  )}`;
};

export const isValidUrl = (url: string) => {
  try {
    return Boolean(new URL(url));
  } catch (_e) {
    return false;
  }
};

export const isValidLinkedInUrl = (url: string): boolean => {
  try {
    const parsedUrl = new URL(url);
    return (
      parsedUrl.hostname === 'www.linkedin.com' ||
      parsedUrl.hostname === 'linkedin.com'
    );
  } catch (_error) {
    return false;
  }
};

export const encodeStringToNumber = (str: string) => {
  let encodedNumber = 0; //= str.length * ;

  for (let i = 0; i < str.length; i++) {
    encodedNumber += str.charCodeAt(i) * Math.pow(10, i);
  }

  return encodedNumber;
};

export const isOrganizationPortalURL = () => {
  const organizationPortalPaths = omit(PATH_ROOT.organizationPortal, [
    'searchFor',
    'similarTo',
  ]);

  const paths: string[] = Object.values(organizationPortalPaths).map(path =>
    typeof path === 'function' ? path() : path,
  );

  for (const path of paths) {
    if (window.location.pathname.includes(path)) {
      return true;
    }
  }
  return false;
};

export const buildOrganizationPortalStartupProfileUrl = (
  startupId: number,
  teamSlug: string | null = 'default',
) =>
  `${getRootUrl()}${PATH_ROOT.organizationPortal.home(teamSlug)}?${PATH_PARAMS.startup_id}=${startupId}`;

export const getUrlHashValue = (key: string) => {
  const hashString = window.location.hash.slice(1); // Remove the leading '#'

  if (!hashString) return null;

  const hashParams = new URLSearchParams(hashString);

  const value = hashParams.get(key);
  if (!value) return null;

  return decodeURIComponent(value);
};

export const updateUrlHash = (key: string, value: string) => {
  const hashString = new URLSearchParams(window.location.hash.slice(1));

  hashString.set(key, encodeURIComponent(value));
  window.location.hash = hashString.toString();

  return hashString;
};

export const hasNavigationHistory = () => {
  return window.history.state?.idx > 0;
};
