import {
  Close,
  CopyAllOutlined,
  Download,
  ExpandLess,
  ExpandMore,
  LinkOutlined,
  OpenInNewOutlined,
  QrCodeOutlined,
} from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { usePitchFormUrl } from 'components/dashboard/StartupInfoSidePanel/pitch/usePitchFormUrl';
import { GLASSDOLLAR_SUPPORT_EMAIL } from 'config';
import domToImage from 'dom-to-image';
import { useSnackbar } from 'notistack';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import QRCode from 'react-qr-code';

const StartupPortalSharingDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { breakpoints } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(breakpoints.down('lg'));
  const { pitchFormURL } = usePitchFormUrl();
  const qrWrapperRef = useRef<HTMLDivElement | null>(null);

  const downloadQRCode = async () => {
    if (!qrWrapperRef.current) {
      console.log('Failed to download QR code');
      enqueueSnackbar('Failed to download QR code', {
        variant: 'error',
      });
      return;
    }

    const dataUrl = await domToImage.toPng(qrWrapperRef.current);

    const link = document.createElement('a');
    link.download = 'startup-portal-qr-code.png';
    link.href = dataUrl;
    link.click();
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen={isMobile}>
      <DialogTitle>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          marginBottom={4}
        >
          <Typography variant='h5'>
            Let startups pitch directly to your inbox
          </Typography>
          <IconButton size='small' onClick={onClose}>
            <Close fontSize='small' />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack gap={4} justifyContent='center'>
          <Card
            sx={{
              marginX: isMobile ? 5 : 10,
              maxWidth: 480,
            }}
          >
            <CardContent ref={qrWrapperRef}>
              <QRCode
                size={8 * 32}
                style={{
                  height: 'auto',
                  maxWidth: '100%',
                  width: '100%',
                  fontFamily: 'Arial, sans-serif',
                }}
                value={pitchFormURL}
                viewBox={`0 0 256 256`}
              />
            </CardContent>
          </Card>
          <Stack direction='row' justifyContent='center'>
            <Button
              color='inherit'
              onClick={downloadQRCode}
              sx={{
                maxWidth: 160,
              }}
            >
              <Download fontSize='small' sx={{ marginRight: 0.5 }} />
              Save QR code
            </Button>
          </Stack>
          <Typography marginX={2} textAlign='center'>
            <Typography color='text.secondary'>
              Get first-party data from companies by sharing your Pitch form{' '}
            </Typography>
            <Typography color='primary.main'>
              <Typography component='span'>startups.glassdollar.com</Typography>
              <CopyToClipboard
                text={pitchFormURL}
                onCopy={() => {
                  enqueueSnackbar('Link copied', {
                    variant: 'success',
                  });
                }}
              >
                <IconButton
                  size='small'
                  sx={{ display: 'inline-block' }}
                  color='primary'
                >
                  <CopyAllOutlined fontSize='small' />
                </IconButton>
              </CopyToClipboard>
            </Typography>
          </Typography>

          {!isMobile && (
            <Typography variant='body2' marginX={2} textAlign='center'>
              Want to use fully private-labeled version? Contact us at{' '}
              <Link
                color='text.primary'
                href={`mailto:${GLASSDOLLAR_SUPPORT_EMAIL}`}
              >
                {GLASSDOLLAR_SUPPORT_EMAIL}
              </Link>
            </Typography>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export const ActionButtons = () => {
  const [qrCodeOpen, setQrCodeOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { pitchFormURL } = usePitchFormUrl();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id='fade-button'
        color='primary'
        variant='outlined'
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={
          open ? (
            <ExpandLess fontSize='small' />
          ) : (
            <ExpandMore fontSize='small' />
          )
        }
      >
        Pitch form
      </Button>
      <Menu
        id='fade-menu'
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <CopyToClipboard
          text={pitchFormURL}
          onCopy={() => {
            enqueueSnackbar('Link copied', { variant: 'success' });
            captureAnalyticsEvent('Copy pitch form link');
            handleClose();
          }}
        >
          <MenuItem>
            <Stack direction='row' gap={1} width='100%'>
              <Typography variant='body2'>Copy link</Typography>
              <LinkOutlined color='primary' sx={{ marginLeft: 'auto' }} />
            </Stack>
          </MenuItem>
        </CopyToClipboard>
        <MenuItem
          onClick={() => {
            window.open(pitchFormURL, '_blank');
            handleClose();
          }}
        >
          <Stack direction='row' gap={1} width='100%'>
            <Typography variant='body2'>View form</Typography>
            <OpenInNewOutlined
              fontSize='small'
              color='primary'
              sx={{ marginLeft: 'auto' }}
            />
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setQrCodeOpen(true);
            handleClose();
          }}
        >
          <Stack direction='row' gap={1} width='100%'>
            <Typography variant='body2'>Get QR code</Typography>
            <QrCodeOutlined
              fontSize='small'
              color='primary'
              sx={{ marginLeft: 'auto' }}
            />
          </Stack>
        </MenuItem>
      </Menu>
      <StartupPortalSharingDialog
        onClose={() => setQrCodeOpen(false)}
        open={qrCodeOpen}
      />
    </>
  );
};
