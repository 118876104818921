import { DomainAdd as DomainAddIcon } from '@mui/icons-material';
import { Button, SxProps } from '@mui/material';

export const AddStartupButton = ({
  setAddStartupInProgress,
  sx,
}: {
  setAddStartupInProgress: () => void;
  sx?: SxProps;
}) => {
  return (
    <Button
      startIcon={<DomainAddIcon />}
      color='primary'
      variant={'outlined'}
      onClick={setAddStartupInProgress}
      sx={sx}
    >
      Add new startup
    </Button>
  );
};
