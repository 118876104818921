import { Stack, Tooltip, Divider, useTheme, Zoom, Link } from '@mui/material';
import { ReactNode, useState } from 'react';
import {
  ContentCopy as ContentCopyIcon,
  DoneAll as DoneAllIcon,
} from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';

const TooltipPreviewAndCopy = ({
  onTextClick,
  textToCopy,
  previewContent,
  children,
}: {
  onTextClick?: () => void;
  previewContent: ReactNode;
  textToCopy: string;
  children: React.ReactElement;
}) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const theme = useTheme();

  const ctaIconStyle = {
    float: 'right',
    cursor: 'pointer',
  };

  const tooltipStyle = {
    tooltip: {
      sx: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        boxShadow: theme.shadows[2],
        maxWidth: 'none',
      },
    },
  };

  return (
    <Tooltip
      slotProps={tooltipStyle}
      title={
        <Stack direction='row' gap={1}>
          {onTextClick ? (
            <Link onClick={onTextClick}>{previewContent}</Link>
          ) : (
            previewContent
          )}
          <Divider orientation='vertical' flexItem />
          <CopyToClipboard
            text={textToCopy}
            onCopy={() => {
              setCopiedToClipboard(true);
            }}
          >
            {!copiedToClipboard ? (
              <ContentCopyIcon fontSize='small' sx={ctaIconStyle} />
            ) : (
              <Zoom in={copiedToClipboard}>
                <DoneAllIcon
                  fontSize='small'
                  color='primary'
                  sx={ctaIconStyle}
                />
              </Zoom>
            )}
          </CopyToClipboard>
        </Stack>
      }
    >
      {children}
    </Tooltip>
  );
};

export { TooltipPreviewAndCopy };
