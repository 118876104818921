import { captureMessage } from '@sentry/react';
import { useInsertStartupsIfNotExistMutation } from 'apollo/generated/sdkShared';
import { useStartupSidePanel } from 'contexts/StartupSidePanelContext';
import { useCallback } from 'react';
import { sanitizeDomain } from 'utils/general';

type SearchStartupResult = {
  domain: string;
  name: string;
  logo_url?: string | null;
  startup_id?: number | null;
  description?: string | null;
  long_description?: string | null;
};

export const useOpenStartupSearchResult = () => {
  const [insertStartupsIfNotExist] = useInsertStartupsIfNotExistMutation();
  const { openStartupSidePanel } = useStartupSidePanel();

  const openSearchStartupSidePanel = useCallback(
    (startup: SearchStartupResult) => {
      if (startup.startup_id) {
        openStartupSidePanel(startup.startup_id);
        return;
      }

      insertStartupsIfNotExist({
        variables: {
          objects: [
            {
              domain: sanitizeDomain(startup.domain),
              name: startup.name,
              logo_url: startup.logo_url,
              long_description: startup.long_description || startup.description,
            },
          ],
        },
      }).then(({ data }) => {
        if (data?.insert_suppliers?.returning[0]) {
          openStartupSidePanel(data.insert_suppliers.returning[0].id);
        } else {
          captureMessage(
            `Failed to insert startup from search results, domain: ${startup.domain}`,
          );
        }
      });
    },
    [insertStartupsIfNotExist, openStartupSidePanel],
  );

  return { openSearchStartupSidePanel };
};
