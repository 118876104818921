import { Box } from '@mui/material';
import { SearchV2 } from 'components/search/SearchV2';

const SearchTraditionalPage = () => {
  return (
    <Box marginTop={2}>
      <SearchV2 embeddedInCurrentList={null} />
    </Box>
  );
};

export default SearchTraditionalPage;
