import {
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Box,
  MenuItem,
  Typography,
  SxProps,
  useTheme,
  Grid2,
} from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';

const SelectFilter = ({
  options,
  label,
  value,
  onValueChange,
  onFocus,
  sx,
}: {
  options: ({ value: string | number; label: string } | string)[];
  value: string;
  label: string;
  onValueChange: (value: string) => void;
  onFocus?: () => void;
  sx?: SxProps;
}) => {
  const { spacing } = useTheme();
  const id = `select-filter-${label.toLowerCase()}-label`;

  return (
    <Grid2 container>
      <FormControl sx={{ ...sx, width: '100%' }}>
        <InputLabel id={id} size='small'>
          {label}
        </InputLabel>
        <Select
          labelId={id}
          value={value}
          label={label}
          onFocus={onFocus}
          onChange={e => onValueChange(e.target.value)}
          size='small'
          endAdornment={
            value && (
              <Box
                sx={{
                  backgroundColor: 'white',
                  width: spacing(3),
                  zIndex: 1,
                  lineHeight: spacing(2),
                }}
              >
                <IconButton
                  sx={{
                    width: spacing(3),
                    height: spacing(3),
                  }}
                  onClick={() => onValueChange('')}
                >
                  <ClearIcon fontSize='small' />
                </IconButton>
              </Box>
            )
          }
        >
          {options.map(option => {
            const label = typeof option === 'string' ? option : option.label;
            const value = typeof option === 'string' ? option : option.value;

            return (
              <MenuItem key={label} value={value}>
                <Typography variant='body2'>{label}</Typography>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Grid2>
  );
};

export default SelectFilter;
