import { LoadingButton } from '@mui/lab';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import {
  GetThreadMessagesSubscription,
  useCreateMessageMutation,
  useGetThreadMessagesSubscription,
} from 'apollo/generated/sdkShared';
import BaseScrollbar2 from 'components/base/BaseScrollbar2';
import {
  getStartupFunnelLabelOrder,
  StartupFunnelLabel,
} from 'components/dashboard/StartupInfoSidePanel/StartupLabelChipSelector';
import LoadingScreen from 'components/LoadingScreen';
import { getStartupSignalLabelByOrder } from 'components/startups/StartupSignalLabel';
import useBreakpoints from 'hooks/useBreakpoints';
import { orderBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { ConversationMessage } from './ConversationMessage';
import { useNavigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';
import { captureAnalyticsEvent } from 'plugins/Analytics';

export type ThreadTargetPerson = {
  id: number;
  full_name: string;
  email: string;
};

const INTERNAL_WIDTH_IN_PX = 900;

export const Conversation = ({
  threadId,
  targetPerson,
}: {
  threadId: number;
  targetPerson?: ThreadTargetPerson | null;
}) => {
  const { isBelowL } = useBreakpoints();
  const [messages, setMessages] = useState<
    GetThreadMessagesSubscription['messages']
  >([]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useGetThreadMessagesSubscription({
    variables: {
      threadId,
    },
    onData: ({ data }) => {
      const newMessages = data.data?.messages;

      if (!newMessages) return;

      if (newMessages.length === 0) {
        enqueueSnackbar('This chat does not exist', {
          variant: 'error',
        });
        captureAnalyticsEvent('Search assistant thread not found', {
          threadId,
        });
        navigate(PATH_ROOT.search.assistant);
      }

      const lastBotMessageId = newMessages
        ? Math.max(
            ...newMessages.filter(m => m.role === 'assistant').map(m => m.id),
          )
        : null;

      // We want these state updates to be batched together
      // so that the component only re-renders once and no glitching occurs
      setMessages(newMessages);
      if (waitingAfterLastBotMessageId !== lastBotMessageId)
        setWaitingAfterLastBotMessageId(null);
    },
  });

  const [waitingAfterLastBotMessageId, setWaitingAfterLastBotMessageId] =
    useState<number | null>(null);

  const scrollRef = useRef<HTMLDivElement>(null);
  const lastMessageRef = useRef<HTMLDivElement>(null);

  const orderedMessages = messages ? orderBy(messages, 'id', 'asc') : null;

  const maxId = orderedMessages
    ? Math.max(...orderedMessages.map(m => m.id))
    : 0;
  const lastBotMessageId = orderedMessages?.filter(m => m.role === 'assistant')
    ? Math.max(
        ...orderedMessages.filter(m => m.role === 'assistant').map(m => m.id),
      )
    : -1;

  const { submitMessage, newMessageLoading } = useSubmitMessage({
    threadId,
    setWaitingAfterLastBotMessageId,
    lastBotMessageId: lastBotMessageId || maxId,
    setMessages,
  });

  // TODO(@kanevk)
  // To change to floating element instead of scrolling to bottom
  useEffect(() => {
    if (!lastMessageRef.current) return;

    const observer = new ResizeObserver(() => {
      // Scroll to the top of the last message
      if (scrollRef.current && lastMessageRef.current) {
        const lastMessageTop = lastMessageRef.current.offsetTop;
        scrollRef.current.scrollTop = lastMessageTop;
      }
    });

    observer.observe(lastMessageRef.current);

    return () => observer.disconnect();
  }, [messages.length]);

  if (!orderedMessages?.length) {
    return (
      <Stack sx={{ paddingTop: 12 }}>
        <LoadingScreen />
      </Stack>
    );
  }

  return (
    <Stack spacing={2} height={'calc(100vh - 264px)'}>
      <Stack
        spacing={2}
        height='100% '
        sx={{
          overflowY: 'auto',
        }}
      >
        <BaseScrollbar2
          scrollableNodeProps={{
            ref: scrollRef,
          }}
          sx={{ paddingX: 3, paddingTop: 3, height: 1, maxHeight: '100%' }}
        >
          <Stack alignItems='center'>
            <Box width={isBelowL ? '100%' : `${INTERNAL_WIDTH_IN_PX}px`}>
              {orderedMessages.map((message, i) => (
                <ConversationMessage
                  {...(i === orderedMessages.length - 1 && {
                    ref: lastMessageRef,
                  })}
                  key={
                    message.role === 'assistant'
                      ? `assistant-message-${i}`
                      : `user-message-${i}`
                  }
                  targetPerson={targetPerson}
                  loading={Boolean(
                    waitingAfterLastBotMessageId && !message.body,
                  )}
                  submitMessage={submitMessage}
                  newMessageLoading={newMessageLoading}
                  message={{
                    id: message.id,
                    role: message.role,
                    body: message.body,
                    solution_categories: message.solution_categories,
                    startups: message.referenced_startups.map(s => ({
                      id: s.startup.id,
                      name: s.startup.name,
                      domain: s.startup.domain,
                      highlight: s.highlight,
                      category: s.category,
                      startup: {
                        ...s.startup,
                        signal_label: getStartupSignalLabelByOrder(
                          s.startup.signal_labels,
                        ),
                        funnel_label: s.startup.funnel_labels
                          ? getStartupFunnelLabelOrder(
                              s.startup.funnel_labels as {
                                value: StartupFunnelLabel;
                              }[],
                            )
                          : null,
                      },
                    })),
                    email_content: message.email_content,
                    created_at: new Date(message.created_at),
                  }}
                />
              ))}
              {waitingAfterLastBotMessageId && (
                <ConversationMessage
                  key={`assistant-message-${waitingAfterLastBotMessageId + 1}`}
                  loading
                  targetPerson={targetPerson}
                  submitMessage={submitMessage}
                  newMessageLoading={newMessageLoading}
                  message={{
                    id: waitingAfterLastBotMessageId,
                    role: 'assistant',
                    body: '',
                    solution_categories: [],
                    startups: [],
                    email_content: null,
                    created_at: new Date(),
                  }}
                />
              )}
            </Box>
          </Stack>
        </BaseScrollbar2>
      </Stack>
      <Stack alignItems='center'>
        <Box width={isBelowL ? '100%' : `${INTERNAL_WIDTH_IN_PX}px`}>
          <ChatInputBox
            submitMessage={submitMessage}
            newMessageLoading={newMessageLoading}
          />
          <Stack direction='row' justifyContent='flex-end'>
            <Typography variant='caption' color='text.secondary' marginTop={1}>
              This conversation is only visible to you.
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};

const ChatInputBox = ({
  submitMessage,
  newMessageLoading,
}: {
  submitMessage: (message: string) => void;
  newMessageLoading: boolean;
}) => {
  const [messageInput, setMessageInput] = useState('');
  const handleSubmit = () => {
    if (!messageInput.trim()) return;
    setMessageInput('');
    submitMessage(messageInput);
  };

  return (
    <TextField
      autoFocus
      multiline
      placeholder='Type a message. Use Shift + Enter for new line.'
      maxRows={4}
      value={messageInput}
      onChange={e => setMessageInput(e.target.value)}
      onKeyDown={e => {
        if (e.key === 'Enter' && e.shiftKey) {
          return;
        }

        if (newMessageLoading) return;

        if (e.key === 'Enter') {
          handleSubmit();
          e.preventDefault();
          return;
        }
      }}
      fullWidth
      sx={{
        flexGrow: 2,
        paddingLeft: 1,
      }}
      InputProps={{
        sx: {
          paddingY: 1.5,
        },
        endAdornment: (
          <LoadingButton
            sx={{ height: '100%' }}
            onClick={handleSubmit}
            loading={newMessageLoading}
          >
            Send
          </LoadingButton>
        ),
      }}
    />
  );
};

const useSubmitMessage = ({
  threadId,
  setWaitingAfterLastBotMessageId,
  lastBotMessageId,
  setMessages,
}: {
  threadId: number;
  setWaitingAfterLastBotMessageId: (messageId: number | null) => void;
  lastBotMessageId: number;
  setMessages: Dispatch<
    SetStateAction<GetThreadMessagesSubscription['messages']>
  >;
}) => {
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [createMessage] = useCreateMessageMutation();
  const { enqueueSnackbar } = useSnackbar();

  const submitMessage = async (message: string) => {
    try {
      const normalizedMessage = message.trim().replace(/\n/g, '<br>');

      setWaitingAfterLastBotMessageId(lastBotMessageId);
      setWaitingForResponse(true);

      setMessages(prevMessages => [
        ...prevMessages,
        {
          __typename: 'user_copilot_messages',
          id: lastBotMessageId + 1,
          role: 'user',
          body: normalizedMessage,
          created_at: new Date().toISOString(),
          referenced_startups: [],
          solution_categories: [],
        },
      ]);

      await createMessage({
        variables: {
          threadId,
          text: normalizedMessage,
        },
      });

      setWaitingAfterLastBotMessageId(null);
      setWaitingForResponse(false);
    } catch (e) {
      captureException(e);
      console.error(e);
      enqueueSnackbar('Failed to send message', { variant: 'error' });
      setWaitingAfterLastBotMessageId(null);
      setWaitingForResponse(false);
    }
  };

  return { submitMessage, newMessageLoading: waitingForResponse };
};
