import {
  Box,
  Button,
  buttonClasses,
  ClickAwayListener,
  Popper,
  Theme,
} from '@mui/material';
import { useRef, useState } from 'react';
import { DepartmentType } from 'components/engagement/people/AddPersonModal';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import React from 'react';
import { AddDepartmentDialog } from 'components/settings/Departments';
import { captureException } from '@sentry/react';
import { useSnackbar } from 'notistack';
import { ArrowDropDown } from '@mui/icons-material';
import { usePersonDepartmentActions } from './hooks/useDepartmentActions';
import { DepartmentAutocomplete } from './DepartmentAutocomplete';
import LightTooltip from 'components/LightTooltip';

const DEPARTMENT_BUTTON_STYLES = {
  button: {
    fontWeight: 600,
    backgroundColor: 'transparent',
    padding: 0,
    minWidth: 0,
    [`&.${buttonClasses.root}`]: {
      minWidth: 0,
    },
    '& .MuiButton-endIcon': {
      marginLeft: 0,
    },
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  helpIcon: {
    padding: '2px',
    marginLeft: '4px',
    opacity: 0.6,
    '&:hover': {
      backgroundColor: 'action.hover',
    },
  },
} as const;

const AUTOCOMPLETE_STYLES = {
  boxWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    color: 'text.secondary',
    position: 'relative',
  },
  popper: {
    backgroundColor: 'background.paper',
    zIndex: (theme: Theme) => theme.zIndex.modal,
    width: '300px',
  },
  paper: {
    borderTopLeftRadius: '0 !important',
    borderTopRightRadius: '0 !important',
  },
  formControl: {
    width: '100%',
    marginTop: 1,
  },
  textField: {
    '& .MuiInputBase-root': {
      backgroundColor: 'action.hover',
      borderTopLeftRadius: '8px !important',
      borderTopRightRadius: '8px !important',
      px: 1,
      py: 0.5,
    },
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '210px',
  },
  unlinkButton: {
    minWidth: 'auto',
    padding: '2px 8px',
    fontSize: '0.75rem',
    marginLeft: 'auto',
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
  },
} as const;

const DepartmentButton = React.forwardRef<
  HTMLButtonElement,
  {
    name?: string;
    onClick: () => void;
  }
>(({ name, onClick }, ref) => {
  return (
    <Box ref={ref}>
      <Button
        disableRipple
        variant='text'
        color='inherit'
        onClick={onClick}
        endIcon={<ArrowDropDown />}
        sx={DEPARTMENT_BUTTON_STYLES.button}
      >
        {name || 'Select department'}
      </Button>
    </Box>
  );
});

DepartmentButton.displayName = 'DepartmentButton';

const SelectDepartment = ({
  department,
  personId,
}: {
  department: DepartmentType | null | undefined;
  personId: number;
}) => {
  const [value, setValue] = useState(department?.id);
  const [open, setOpen] = useState(false);
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [inputValue, setInputValue] = useState('');
  const [nestedModalOpen, setNestedModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => setOpen(false);
  const handleToggle = () => setOpen(prevOpen => !prevOpen);

  const handleNewDepartment = (e: React.MouseEvent) => {
    e.preventDefault();
    setNestedModalOpen(true);
    captureAnalyticsEvent('Add Person Modal: New Department button clicked');
  };

  const { id: organizationId } = useCurrentOrganizationFromContext();
  const { departments, updateDepartment, unlinkDepartment } =
    usePersonDepartmentActions(organizationId, personId);

  async function handleSetSelectedDepartment(departmentId: number | undefined) {
    try {
      setOpen(false);
      setValue(departmentId);
      await updateDepartment(departmentId);
    } catch (error) {
      enqueueSnackbar('Error updating department', {
        variant: 'error',
      });
      captureException(error);
    }
  }

  const handleUnlinkDepartment = async () => {
    await unlinkDepartment();
    setValue(undefined);
  };

  return (
    <Box sx={AUTOCOMPLETE_STYLES.boxWrapper}>
      <ClickAwayListener
        onClickAway={() => {
          if (open) {
            handleClose();
          }
        }}
      >
        <Box>
          <LightTooltip title={department?.description || ''} placement='right'>
            <span>
              <DepartmentButton
                name={department?.name}
                onClick={handleToggle}
                ref={anchorEl}
              />
            </span>
          </LightTooltip>

          <Popper
            open={open}
            anchorEl={anchorEl.current}
            placement='bottom-start'
            sx={AUTOCOMPLETE_STYLES.popper}
          >
            <DepartmentAutocomplete
              departments={departments}
              value={value}
              anchorEl={anchorEl.current}
              onDepartmentSelect={handleSetSelectedDepartment}
              onUnlinkDepartment={handleUnlinkDepartment}
              onNewDepartment={handleNewDepartment}
              onInputChange={setInputValue}
            />
            {nestedModalOpen && (
              <AddDepartmentDialog
                open
                setAddDepartmentDialogOpen={setNestedModalOpen}
                defaultName={value ? undefined : inputValue}
                setSelectedDepartment={handleSetSelectedDepartment}
              />
            )}
          </Popper>
        </Box>
      </ClickAwayListener>
    </Box>
  );
};

export default SelectDepartment;
