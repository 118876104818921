import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import LightTooltip from 'components/LightTooltip';
import { orderBy, sum } from 'lodash';
import { DecoratedScoutedStartup } from './types';

const HighlightCell = (
  params: GridRenderCellParams<
    DecoratedScoutedStartup,
    { [key: string]: string[] }
  >,
) => {
  const highlightMapping = params.value;

  // Consider moving this to the backend
  const highlights = useMemo(() => {
    const sanitizedHighlights = Object.entries(highlightMapping || {}).reduce<{
      [key: string]: string[];
    }>((obj, [source, sourceHighlights]) => {
      obj[source] = ((sourceHighlights || []) satisfies string[])
        .map(h => h.replace(/<em>and<\/em>/g, 'and'))
        .map(h => h.replace(/<em>or<\/em>/g, 'or'));
      return obj;
    }, {});

    const bestHighlights = orderBy(
      Object.entries(sanitizedHighlights),
      ([, highlights]) => {
        sum(highlights.map(h => h.match(/<em>/g)?.length || 0));
      },
      'desc',
    );

    return bestHighlights.map(([, highlights]) => {
      return highlights.join(' ');
    });
  }, [highlightMapping]);

  const joinedHighlightText = highlights
    .map(h => h.trim())
    .map(h => (h.endsWith('.') ? h.slice(0, -1) : h))
    .join('... ');

  return (
    <LightTooltip
      title={
        <Typography
          variant='body2'
          whiteSpace={'pre-wrap'}
          sx={{
            '& em': {
              fontWeight: 'bold',
              fontStyle: 'normal',
            },
          }}
          dangerouslySetInnerHTML={{
            __html: joinedHighlightText,
          }}
        />
      }
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
      >
        <Typography
          sx={({ typography }) => ({
            whiteSpace: 'pre-wrap',
            '& em': {
              fontWeight: typography.fontWeightBold,
              fontStyle: 'normal',
            },
          })}
          variant='body2'
          dangerouslySetInnerHTML={{
            __html: joinedHighlightText,
          }}
        />
      </Box>
    </LightTooltip>
  );
};

export { HighlightCell };
