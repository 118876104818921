import { CopyAll } from '@mui/icons-material';
import { MenuItem, Typography } from '@mui/material';
import { useCopyStartupListMutation } from 'apollo/generated/sdkShared';
import { BaseSnackbarActionNavigateToList } from 'components/base/BaseSnackbarActions';
import { useSnackbar } from 'notistack';

const DuplicateListMenuItem = ({ listId }: { listId: number }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [copy, { loading }] = useCopyStartupListMutation();
  const handleCopy = async () => {
    try {
      const { errors, data } = await copy({
        variables: {
          sourceListId: listId,
        },
      });

      if (errors) {
        enqueueSnackbar('Failed to duplicate list', { variant: 'error' });
      }

      const newListId =
        data?.copy_startup_list_same_organization.startup_list?.id;

      if (newListId) {
        enqueueSnackbar('Successfully duplicated list', {
          variant: 'success',
          action: <BaseSnackbarActionNavigateToList listId={newListId} />,
          autoHideDuration: 5000,
        });
      }
    } catch (_error) {
      enqueueSnackbar('Failed to duplicate list', { variant: 'error' });
    }
  };

  return (
    <MenuItem
      disabled={loading}
      sx={{ color: 'text.secondary' }}
      onClick={handleCopy}
      key='menu-item-duplicate'
    >
      <CopyAll fontSize='small' sx={{ marginRight: 0.5 }} />
      <Typography variant='body1'>Duplicate</Typography>
    </MenuItem>
  );
};

export default DuplicateListMenuItem;
