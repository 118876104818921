import { Add } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { useCreateLead } from 'components/projectLeads/useCreateLead';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useState } from 'react';

const OPEN_DIALOG_DELAY = 3000;

type StakeholderForTrackAsLeadDialog = {
  id: number;
  full_name: string;
  email: string;
};

export const useTrackAsLeadDialog = () => {
  const [openedForStakeholder, setOpenForStakeholder] =
    useState<StakeholderForTrackAsLeadDialog | null>(null);
  const handleClose = () => setOpenForStakeholder(null);
  const { handleCreateLead } = useCreateLead();

  const trackingDialog = (
    <Dialog
      open={Boolean(openedForStakeholder)}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle>Track outreach as project lead</DialogTitle>
      <DialogContent
        sx={{
          paddingBottom: 0,
        }}
      >
        <Stack sx={{ paddingTop: 2 }}>
          <Typography variant='body2'>
            Capture the outreach
            {openedForStakeholder
              ? ` to ${openedForStakeholder?.full_name}`
              : ''}{' '}
            by creating a new project lead.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='text' color='inherit' onClick={handleClose}>
          No, maybe later
        </Button>
        <Button
          variant='contained'
          startIcon={<Add />}
          onClick={() => {
            captureAnalyticsEvent('Leads Assistant Lead Tracked');
            handleCreateLead({
              title: `Outreach to ${openedForStakeholder?.full_name}`,
              painPointOwnerId: openedForStakeholder?.id,
              source: 'Leads assistant',
            });
          }}
        >
          New Lead
        </Button>
      </DialogActions>
    </Dialog>
  );

  return {
    openTrackingDialog: (stakeholder: StakeholderForTrackAsLeadDialog) => {
      setTimeout(() => setOpenForStakeholder(stakeholder), OPEN_DIALOG_DELAY);
    },
    trackingDialog,
  };
};
