import searchFill from '@iconify/icons-eva/search-fill';
import { Icon as ReactIcon } from '@iconify/react';
import { Close, SearchOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  AutocompleteRenderOptionState,
  Box,
  CircularProgress,
  Fade,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { useGetAutocompleteStartupsQuery } from 'apollo/generated/sdkShared';
import { BaseStartupAvatar } from 'components/base/BaseStartupAvatar';
import useBreakpoints from 'hooks/useBreakpoints';
import { debounce } from 'lodash';
import {
  Dispatch,
  HTMLAttributes,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { useOpenStartupSearchResult } from './useOpenStartupSearchResult';
import { AutocompleteValue } from '@mui/material';
import { captureAnalyticsEvent } from 'plugins/Analytics';

export const Searchbox = ({
  query,
  setQuery,
  loading,
  handleSubmit,
  showSearchButton,
}: {
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
  loading: boolean;
  handleSubmit: (query: string) => void;
  showSearchButton: boolean;
}) => {
  const { isBelowSm } = useBreakpoints();
  const nameAutocompleteState = useNameAutocompleteOptions({
    nameQuery: query,
  });

  const {
    nameMatchResults,
    nameMatchOptions: options,
    loading: optionsLoading,
  } = useDeferredValue(nameAutocompleteState);

  const deferredQuery = useDeferredValue(query);

  const { openSearchStartupSidePanel } = useOpenStartupSearchResult();

  const handleInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key !== 'Enter') return;

      e.preventDefault();
      handleSubmit(query);
    },
    [handleSubmit, query],
  );

  const renderOption = useCallback(
    (
      props: HTMLAttributes<HTMLLIElement>,
      option: string,
      state: AutocompleteRenderOptionState,
    ) => {
      const isFreeSolo = !options.includes(option);

      if (isFreeSolo && option === state.inputValue && option !== '') {
        return (
          <Box
            component='li'
            {...props}
            key={'custom-' + option}
            data-testid='search-custom-option'
            onClick={() => handleSubmit(option)}
          >
            Search for &quot;{option}&quot;
          </Box>
        );
      }

      const match = nameMatchResults.find(m => m.domain === option);

      if (!match) {
        return null;
      }

      return (
        <Box component='li' {...props} key={option}>
          <Stack direction='row' spacing={1} alignItems='center'>
            <BaseStartupAvatar
              key={`avatar-${option}`}
              startup={{
                domain: match.domain,
                logo_url: match.logo_url,
                name: match.name,
              }}
              size='tiny'
            />
            <Typography variant='body1'>{match.name}</Typography>
          </Stack>
        </Box>
      );
    },
    [nameMatchResults, options, handleSubmit],
  );

  const renderGroup = useCallback(
    (params: AutocompleteRenderGroupParams) => {
      if (!params.group) {
        return (
          <Box component={'li'} key={params.key}>
            <Stack spacing={1}>{params.children}</Stack>
          </Box>
        );
      }

      return (
        <Box component={'li'} key={params.key}>
          <Stack
            marginY={1}
            paddingLeft={1}
            spacing={1}
            direction='row'
            alignItems='center'
          >
            <Typography
              variant='body2'
              sx={{
                color: t => t.palette.text.secondary,
                fontWeight: t => t.typography.fontWeightSemiBold,
              }}
            >
              {params.group}
            </Typography>
            {optionsLoading && <CircularProgress size={16} />}
          </Stack>
          <Stack spacing={1}>{params.children}</Stack>
        </Box>
      );
    },
    [optionsLoading],
  );

  const onChange = useCallback(
    (
      _e: SyntheticEvent<Element, Event>,
      value: AutocompleteValue<string, false, false, true>,
    ) => {
      setQuery(value || '');
      if (value) handleSubmit(value);
      if (value && options.includes(value)) {
        const option = nameMatchResults.find(m => m.domain === value);
        if (option) {
          openSearchStartupSidePanel(option);
          captureAnalyticsEvent('Startups search name suggestion selected', {
            searchNameQuery: deferredQuery,
            searchNameOption: option.domain,
          });
        }
      }
    },
    [
      handleSubmit,
      nameMatchResults,
      openSearchStartupSidePanel,
      options,
      setQuery,
      deferredQuery,
    ],
  );

  const renderInput = useCallback(
    (params: TextFieldProps) => {
      // eslint-disable-next-line
      const inputValue = (params.inputProps?.value as string) || '';

      return (
        <EmbeddedTextField
          params={params}
          buttons={
            <Fade in={!!query} mountOnEnter unmountOnExit>
              <InputAdornment position='end'>
                {isBelowSm ? (
                  <IconButton
                    disabled={!inputValue || loading}
                    onClick={() => handleSubmit(inputValue)}
                    sx={({ palette }) => ({
                      color: loading ? palette.text.disabled : 'inherit',
                    })}
                  >
                    <SearchOutlined />
                  </IconButton>
                ) : (
                  <>
                    <IconButton
                      onClick={() => setQuery('')}
                      sx={{ marginRight: 1 }}
                    >
                      <Close />
                    </IconButton>

                    {showSearchButton && (
                      <LoadingButton
                        loading={loading}
                        disabled={!inputValue}
                        onClick={() => handleSubmit(inputValue)}
                        sx={{ width: 200, padding: 1 }}
                        variant='contained'
                      >
                        Search
                      </LoadingButton>
                    )}
                  </>
                )}
              </InputAdornment>
            </Fade>
          }
        />
      );
    },
    [query, isBelowSm, loading, showSearchButton, handleSubmit, setQuery],
  );

  const groupBy = useCallback(
    (option: string) => {
      if (options.includes(option)) {
        return 'Name matches';
      }

      return '';
    },
    [options],
  );

  const filterOptions = useCallback(
    (options: string[], params: { inputValue: string }) => {
      if (params.inputValue === '') {
        return [];
      }

      return [params.inputValue, ...options];
    },
    [],
  );

  const onInputChange = useCallback(
    (_e: unknown, value: string) => {
      setQuery(value);
    },
    [setQuery],
  );

  return (
    <Autocomplete<string, false, false, true>
      freeSolo
      options={options}
      value={query}
      onInputChange={onInputChange}
      sx={{ width: '100%', marginBottom: 4 }}
      autoHighlight
      groupBy={groupBy}
      noOptionsText={'No results found'}
      renderGroup={renderGroup}
      onChange={onChange}
      onKeyDown={handleInputKeyDown}
      renderOption={renderOption}
      filterOptions={filterOptions}
      renderInput={renderInput}
    />
  );
};

const useNameAutocompleteOptions = ({ nameQuery }: { nameQuery: string }) => {
  const [debouncedQuery, setDebouncedQuery] = useState(nameQuery);

  const debounceSetQuery = useMemo(() => {
    console.log('debounceSetQuery');
    return debounce(
      query => {
        setDebouncedQuery(query);
      },
      500,
      {
        leading: true,
        trailing: true,
      },
    );
  }, []);

  useEffect(() => {
    debounceSetQuery(nameQuery);

    return () => {
      debounceSetQuery.cancel();
    };
  }, [nameQuery, debounceSetQuery]);

  const { data, previousData, loading } = useGetAutocompleteStartupsQuery({
    variables: {
      query: debouncedQuery,
    },
  });

  const nameMatches = useMemo(
    () => (data || previousData)?.startups_name_autocomplete.results || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.startups_name_autocomplete.results],
  );

  return useMemo(() => {
    return {
      nameMatchResults: nameMatches,
      nameMatchOptions: nameMatches.map(match => match.domain),
      loading,
    };
  }, [nameMatches, loading]);
};

export const EmbeddedTextField = ({
  params,
  buttons,
}: {
  params?: TextFieldProps;
  buttons: ReactElement;
}) => {
  return (
    <TextField
      {...params}
      autoFocus
      fullWidth
      data-test='startups-search'
      placeholder='Search startups by name, url or topic'
      InputProps={{
        ...(params?.InputProps || {}),
        sx: ({ palette, transitions, customShadows }) => ({
          background: palette.grey[0],
          height: 60,
          transition: transitions.create(['box-shadow', 'width'], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.shorter,
          }),
          '&.Mui-focused': { boxShadow: customShadows.z8 },
          '& fieldset': {
            borderWidth: `1px !important`,
            borderColor: `${palette.grey[500_32]} !important`,
          },
        }),
        startAdornment: (
          <InputAdornment position='start'>
            <Box
              component={ReactIcon}
              icon={searchFill}
              sx={{ color: 'text.disabled', marginLeft: 1 }}
            />
          </InputAdornment>
        ),
        endAdornment: buttons,
      }}
    />
  );
};
