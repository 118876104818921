import {
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useTheme,
  Zoom,
} from '@mui/material';
import {
  OrganizationDepartments,
  People,
  PersonInnovationThemes,
  StartupForCardFragmentFragmentDoc,
} from 'apollo/generated/sdkInnovationManager';
import {
  StartupForCardFragmentFragment as StartupForCardFragment,
  useRecommendThemesForPersonMutation,
} from 'apollo/generated/sdkShared';
import { BaseStartupCard } from 'components/base/BaseStartupCard';
import {
  getStartupFunnelLabelOrder,
  StartupFunnelLabel,
} from 'components/dashboard/StartupInfoSidePanel/StartupLabelChipSelector';
import StandardErrorSection from 'components/StandardErrorSection';
import { getStartupSignalLabelByOrder } from 'components/startups/StartupSignalLabel';
import { compact, groupBy } from 'lodash';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useEffect } from 'react';
import { SectionTitle } from './SectionTitle';

export const StakeholderRecommendedStartups = ({
  person,
}: {
  person: Pick<People, 'id' | 'full_name' | 'job_title'> & {
    organization_department?: Pick<
      OrganizationDepartments,
      'id' | 'name' | 'description'
    > | null;
    innovation_themes: Pick<
      PersonInnovationThemes,
      'id' | 'innovation_theme'
    >[];
  };
}) => {
  const theme = useTheme();
  const recommendationsCacheKey = compact([
    person.job_title,
    person.innovation_themes?.map(t => t.innovation_theme),
    person.organization_department?.description,
  ]).join('-');

  const [generateRecommendations, { data, loading, error }] =
    useRecommendThemesForPersonMutation({
      variables: { personId: person.id },
    });

  useEffect(() => {
    generateRecommendations({
      variables: { personId: person.id },
    });
  }, [recommendationsCacheKey, generateRecommendations, person.id]);

  if (!recommendationsCacheKey) {
    return (
      <Stack spacing={1}>
        <SectionTitle
          title={`AI-recommended startups for ${person.full_name}`}
        />

        <Typography variant='body2' color='textSecondary'>
          Fill in {person.full_name}
          {"'"}s <b>job title</b> and/or <b>interests</b> to see tailored
          startup recommendations.
        </Typography>
      </Stack>
    );
  }

  if (error)
    return (
      <Stack spacing={1}>
        <SectionTitle
          title={`AI-recommended startups for ${person.full_name}`}
        />

        <StandardErrorSection title={'Error fetching recommendations'} />
      </Stack>
    );

  const recommendationsByTopic = groupBy(
    data?.recommend_startups_for_stakeholder || [],
    'topic',
  );

  return (
    <Stack spacing={2}>
      <Stack direction='row' spacing={1}>
        <SectionTitle
          title={`AI-recommended startups for ${person.full_name}`}
        />

        <Zoom in={loading}>
          <Stack direction='row' alignItems='center'>
            <CircularProgress size={theme.spacing(3)} />
          </Stack>
        </Zoom>
      </Stack>
      {Object.keys(recommendationsByTopic).map(theme => (
        <CategorySection
          key={theme}
          categoryName={theme}
          stakeholderId={person.id}
          startups={recommendationsByTopic[theme].map(({ startup }) => {
            if (!startup) throw new Error('Startup is missing');

            return {
              ...startup,
              signal_label: getStartupSignalLabelByOrder(
                startup!.signal_labels,
              ),
              funnel_label: getStartupFunnelLabelOrder(
                startup!.funnel_labels as { value: StartupFunnelLabel }[],
              ),
            };
          })}
        />
      ))}
    </Stack>
  );
};

const CategorySection = ({
  stakeholderId,
  categoryName,
  startups,
}: {
  stakeholderId: number;
  categoryName: string;
  startups: StartupForCardFragment[];
}) => {
  const theme = useTheme();

  return (
    <Stack paddingBottom={2}>
      <Stack direction='row' justifyContent='space-between'>
        <Typography
          variant='body2'
          color='textSecondary'
          fontWeight={theme.typography.fontWeightBold}
          marginBottom={2}
        >
          {categoryName}
        </Typography>
      </Stack>
      <Grid container spacing={2}>
        {startups.map(startup => (
          <BaseStartupCard
            key={startup.id}
            avatarSize='small'
            startup={{
              ...startup,
              signal_label: undefined,
              funnel_label: undefined,
            }}
            trackFn={() =>
              captureAnalyticsEvent(
                'Stakeholder recommended startup profile open',
                {
                  startupId: startup!.id,
                  stakeholderId,
                },
              )
            }
            enrichment={{
              autoEnrich: true,
              queryDocument: StartupForCardFragmentFragmentDoc,
            }}
          />
        ))}
      </Grid>
    </Stack>
  );
};
